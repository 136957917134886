//import libs
import { TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import classnames from "classnames";
import React from "react";

// Import utils
import { displayDate, stringTransformer, truncateString } from "@utils";

// Styles
import * as style from "../../style.module.scss";

interface Row {
    id: string;
    name: string;
    contractRef: string;
    category: string;
    created: string;
    taskDefinitionKey: string;
    variables: any;
    tenantId: string;
    contractId: string;
    salessystemOrderId?: string;
    approved: boolean;
    supplierName: string;
    permissions: Record<string, boolean>;
    recalled?: boolean | null;
    claimNo?: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    permissions: any;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
    isCompleted?: boolean;
}

const TasksRow = ({
    id,
    row,
    rowIndex,
    isColVisible,
    callbacks,
    isCompleted = false,
    permissions,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            data-testid="tasks-row"
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            className={classnames(style.row, {
                [style.isClickable]:
                    row.taskDefinitionKey !== "priceDataApprovalTask" ||
                    (row.taskDefinitionKey === "priceDataApprovalTask" &&
                        !!permissions?.hasPermissionToEditDataTables),
            })}
            onClick={() =>
                row.taskDefinitionKey === "priceDataApprovalTask" &&
                !permissions?.hasPermissionToEditDataTables
                    ? undefined
                    : callbacks && callbacks?.viewTaskDetails(row?.id)
            }
        >
            {isColVisible("name") && (
                <TableCell>
                    <Typography variant="caption2">{row.name}</Typography>
                </TableCell>
            )}

            {isColVisible("contractRef") && (
                <TableCell>
                    {row.contractRef ? (
                        <Tooltip arrow placement="top" title={row.contractRef}>
                            <Typography variant="caption2">
                                {truncateString(row.contractRef, 30)}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}
            {isColVisible("taskDefinitionKey") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.taskDefinitionKey
                            ? stringTransformer(row.taskDefinitionKey)
                            : ""}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("category") && (
                <TableCell>
                    <Typography variant="caption2">{row.category}</Typography>
                </TableCell>
            )}

            {isColVisible("salessystemOrderId") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.salessystemOrderId ? row.salessystemOrderId : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("supplierName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.supplierName ? row.supplierName : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("claimNo") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.claimNo ? row.claimNo : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("created") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.created)}
                    </Typography>
                </TableCell>
            )}

            {isCompleted && isColVisible("approved") && (
                <TableCell>
                    <Typography variant="caption2">
                        {typeof row.approved === "boolean"
                            ? row.approved
                                ? "Yes"
                                : `No ${(row?.taskDefinitionKey === "claimApprovalTask" || row?.taskDefinitionKey === "claimOverrideApprovalTask" || row?.taskDefinitionKey === "bpfApprovalTask") && !!row?.recalled ? "(Recalled)" : ""}`
                            : "-"}
                    </Typography>
                </TableCell>
            )}
        </TableRow>
    );
};

export default TasksRow;
