// Libs
import { Box, Typography, Grid } from "@mui/material";
import React, { Fragment } from "react";
import classnames from "classnames";
import Tooltip from "@mui/material/Tooltip";

// Own component
import { FieldRenderer, LoadingWrapper, Card } from "@components";

// Utils
import {
    constantMapper,
    displayDate,
    isArrayWithContent,
    truncateString,
} from "@utils";

// Style
import * as style from "./style.module.scss";
import { OutcomeDetails as OutcomeDetailsType } from "@types";

/**
 * Props type
 */
interface Props {
    outcome: OutcomeDetailsType;
    id: string;
    isFinancialConditions?: boolean;
    downloadBpf?: {
        onDownload: (fileId: string) => void;
        loading: boolean;
    };
    downloadEvidence: {
        onDownload: (contractId: string, evidenceId: string) => void;
        loading: boolean;
        canDownload: boolean | undefined;
    };
    downloadApheresis?: {
        onDownload: () => void;
        loading: boolean;
    };
}

/**
 * Outcome Details
 */
const OutcomeDetails = ({
    outcome,
    id,
    downloadEvidence,
    downloadBpf,
    isFinancialConditions,
    downloadApheresis,
}: Props) => {
    /**
     * Render
     */
    return (
        <Fragment>
            <Card title="Order" id="order" firstItemOnScreen>
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-salessystemOrderId`}
                            label="Cquence Id"
                            value={outcome?.order?.salessystemOrderId}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-sapOrderNo`}
                            label="SAP order no"
                            value={outcome?.order?.sapOrderNo}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-infusionDate`}
                            label="Infusion date"
                            value={displayDate(outcome?.order?.infusionDate)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-treatmentSite`}
                            label="Treatment Site"
                            value={outcome?.order?.treatmentSite}
                        />
                    </Grid>
                </Grid>
            </Card>

            <Card title="Contract details" id="contract-details">
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-contractRef`}
                            label="Contract ref"
                            value={outcome?.contract?.ref}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-contractPartnerName`}
                            label="Contract partner"
                            value={outcome?.contract?.partnerName}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-contractValidFrom`}
                            label="Start date"
                            value={displayDate(outcome?.contract?.validFrom)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-contractValidTo`}
                            label="End date"
                            value={displayDate(outcome?.contract?.validTo)}
                        />
                    </Grid>
                </Grid>
            </Card>

            <Card id={`${id}-additional-partners`} title="Additional partners">
                {!!outcome?.contract?.additionalPartners &&
                !!isArrayWithContent(outcome?.contract?.additionalPartners) ? (
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        {outcome?.contract?.additionalPartners?.map(
                            (partner, index) => (
                                <Grid item xs={12} key={index} md={6}>
                                    <FieldRenderer
                                        id={`${id}-${index}`}
                                        label=""
                                        value={partner?.name}
                                    />
                                </Grid>
                            ),
                        )}
                    </Grid>
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Typography variant="caption1">
                            No additional partners found!
                        </Typography>
                    </Box>
                )}
            </Card>

            {!isFinancialConditions && (
                <Card title="Milestone" id="milestone">
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-milestoneNo`}
                                label="Milestone number"
                                value={
                                    outcome?.outcome?.milestoneNo || undefined
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-milestoneDescription`}
                                label="Milestone description"
                                value={
                                    outcome?.outcome?.milestoneDescription ||
                                    undefined
                                }
                            />
                        </Grid>
                    </Grid>
                </Card>
            )}

            <Card
                title={isFinancialConditions ? "Financial Details" : "Outcome"}
                id={isFinancialConditions ? "financial-details" : " outcome"}
            >
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    {!isFinancialConditions && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-milestoneMet`}
                                label="Milestone met"
                                value={
                                    outcome?.outcome?.milestoneMet
                                        ? "Yes"
                                        : "No"
                                }
                            />
                        </Grid>
                    )}
                    {!isFinancialConditions && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-outcomeDate`}
                                label="Outcome date"
                                value={displayDate(
                                    outcome?.outcome?.outcomeDate,
                                )}
                            />
                        </Grid>
                    )}

                    {!isFinancialConditions && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-percentage`}
                                label="Percentage"
                                value={
                                    outcome?.outcome?.percentage
                                        ? `${outcome?.outcome?.percentage}%`
                                        : undefined
                                }
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-amount`}
                            label="Amount"
                            value={outcome?.outcome?.amount || undefined}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-measure`}
                            label="Measure"
                            value={outcome?.outcome?.measure || undefined}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-vatAmount`}
                            label="VAT amount"
                            value={outcome?.outcome?.vatAmount || undefined}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-vatPercentage`}
                            label="VAT percentage"
                            value={outcome?.outcome?.vatPercentage || undefined}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-action`}
                            label="Action"
                            value={
                                outcome?.outcome?.action
                                    ? constantMapper(outcome?.outcome?.action)
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-reason`}
                            label="Reason"
                            value={outcome?.outcome?.reason}
                        />
                    </Grid>
                </Grid>
            </Card>

            {!!outcome?.apheresisPdf?.name && (
                <Card title="Outcome files" id="outcome-files">
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-apheresis-validation-report`}
                                label="Apheresis validation report"
                                value={outcome?.apheresisPdf?.name || undefined}
                                loading={downloadApheresis?.loading}
                                hasDownloadAction
                                onDownloadClick={() =>
                                    downloadApheresis?.onDownload()
                                }
                            />
                        </Grid>
                    </Grid>
                </Card>
            )}

            {outcome?.outcomeFile?.id && (
                <Card title="Outcome BPF" id="outcome-BPF">
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-outcomeBpf`}
                                label="File name"
                                value={outcome?.outcomeFile?.name || undefined}
                                loading={downloadBpf?.loading}
                                hasDownloadAction
                                onDownloadClick={
                                    outcome?.outcomeFile?.id
                                        ? () =>
                                              downloadBpf?.onDownload(
                                                  outcome.outcomeFile
                                                      .id as string,
                                              )
                                        : undefined
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-outcomeFileType`}
                                label="Type"
                                value={outcome?.outcomeFile?.type || undefined}
                            />
                        </Grid>
                    </Grid>
                </Card>
            )}

            {outcome?.sourceOutcomeFile?.originalFileName && (
                <Card title="Source outcome file" id="source-outcome-file">
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-outcomeFileName`}
                                label="Original file name"
                                value={
                                    outcome?.sourceOutcomeFile?.originalFileName
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-outcomeFileDateReceived`}
                                label="Date received"
                                value={displayDate(
                                    outcome?.sourceOutcomeFile?.dateReceived,
                                )}
                            />
                        </Grid>
                    </Grid>
                </Card>
            )}

            <Card title="Evidences" id="evidences">
                <Grid
                    container
                    item
                    xs={12}
                    mb={4}
                    display="flex"
                    justifyContent={
                        !isArrayWithContent(outcome?.evidences)
                            ? "center"
                            : "flex-start"
                    }
                >
                    <LoadingWrapper
                        id={`${id}-evidences`}
                        loading={downloadEvidence?.loading || false}
                        customMsg={
                            isArrayWithContent(outcome?.evidences)
                                ? undefined
                                : "No files found!"
                        }
                    >
                        {isArrayWithContent(outcome?.evidences) &&
                            outcome?.evidences.map((evidence, index) => (
                                <Grid item xs={5} key={index}>
                                    <Tooltip
                                        arrow
                                        placement="top"
                                        title={evidence.fileName}
                                    >
                                        <Typography
                                            variant="link"
                                            className={classnames(style.link, {
                                                [style.disabledLink]:
                                                    !downloadEvidence?.canDownload,
                                            })}
                                            onClick={() =>
                                                downloadEvidence?.canDownload
                                                    ? downloadEvidence?.onDownload(
                                                          evidence?.contractId,
                                                          evidence?.evidenceId,
                                                      )
                                                    : undefined
                                            }
                                        >
                                            {truncateString(
                                                evidence.fileName,
                                                30,
                                                true,
                                            )}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            ))}
                    </LoadingWrapper>
                </Grid>
            </Card>
        </Fragment>
    );
};

export default OutcomeDetails;
