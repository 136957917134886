//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import React, { Fragment } from "react";

// Own components
import { Dialog } from "@components";

// styles

import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onTaskComplete: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    values: { approved: string };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    bpfName: string;
}

/**
 * Bpf Processing Task
 */
const BpfProcessingTask = ({
    openDialog,
    loading,
    onReset,
    onTaskComplete,
    className,
    values,
    onBlur,
    onChange,
    bpfName,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`tasks-approve-bpf-processing`}
                title={"Approve BPF processing"}
                open={openDialog}
                message={
                    values?.approved === "true" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to approve confirm BPF ${bpfName}
                            <Typography variant="body2" component="span">
                                {` [${bpfName}] `}
                            </Typography>
                            has been processed. This action cannot be undone.
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to confirm BPF ${bpfName}
                            <Typography variant="body2" component="span">
                                {` [${bpfName}] `}
                            </Typography>
                            processing has been cancelled. This action cannot be
                            undone.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onTaskComplete,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <div className={style.header}>
                <Typography variant="h2">{`Approve BPF processing`}</Typography>
            </div>

            <Box mb={3} className={className}>
                <Box my={3}>
                    <Typography variant="body2" color="dark.light">
                        Please confirm the action taken once completed:
                    </Typography>
                </Box>
                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`task-details-task-review`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"true"}
                                id={`task-details-review-contract-approve`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I confirm the BPF report was processed
                                        by finance.
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                id={`task-details-review-contract-reject`}
                                value={"false"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I confirm the processing of this BPF was
                                        cancelled.
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(BpfProcessingTask);
