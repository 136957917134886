// Lib
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { NumericFormat } from "react-number-format";
import React, { Fragment, useMemo } from "react";
import { FormikErrors, FormikTouched } from "formik";

// Own components

// Types
import { AdvancedScale, Brand, Brands } from "@types";

// Style
import * as style from "../style.module.scss";

// Custom hooks
import AdvancedProductScales from "../AdvancedProductScales";

// Utils
import { mapSeparators } from "@utils";

type Props = {
    handleChange: (type: string, value: any) => void;
    type?: string;
    canAddFixedPrice: boolean;
    canAddDiscountPercentage: boolean;
    canAddScales: boolean;
    amount?: string;
    errors: FormikErrors<any>;
    touched?: FormikTouched<any>;
    brand?: Brand;
    brands?: Brands;
    id: string;
    scales?: AdvancedScale[];
    handleProductScales?: (scales: AdvancedScale[]) => void;
    allowedScaleRange?: { periodFrom: string; periodTo: string };
    categoryType?: string;
    currency?: string;
    typeError: { type: string; error: string };
};

const PricingOptionSelector: React.FC<Props> = ({
    handleChange,
    type,
    canAddFixedPrice,
    canAddDiscountPercentage,
    canAddScales,
    amount,
    errors,
    touched,
    brand,
    brands,
    id,
    scales,
    handleProductScales,
    allowedScaleRange,
    categoryType,
    currency,
    typeError,
}: Props) => {
    /**
     * Check if only one option is selected
     */
    const onlyOneOptionSelected = useMemo(() => {
        return (
            [canAddDiscountPercentage, canAddFixedPrice, canAddScales].filter(
                (option: boolean) => option,
            ).length === 1
        );
    }, [canAddDiscountPercentage, canAddFixedPrice, canAddScales]);

    const separators = useMemo(() => mapSeparators(), []);
    return (
        <Fragment>
            <Grid
                mt={3}
                xs={12}
                item
                container
                columnSpacing={4}
                alignItems={"flex-end"}
                mb={3}
            >
                <Grid xs={12} item>
                    {onlyOneOptionSelected ? (
                        <InputLabel shrink>
                            {categoryType === "contractPrice"
                                ? "Fixed price"
                                : categoryType === "discountPercentage"
                                  ? "Fixed discount"
                                  : "Scale"}
                        </InputLabel>
                    ) : (
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="product-type"
                                name="scale"
                                value={type}
                                onChange={event =>
                                    handleChange("type", event.target.value)
                                }
                                row
                            >
                                {canAddFixedPrice && (
                                    <FormControlLabel
                                        value="contractPrice"
                                        control={<Radio size="small" />}
                                        label={
                                            <Typography
                                                variant="body2"
                                                color={
                                                    (!!brands &&
                                                        !brand?.brandId) ||
                                                    (!!categoryType &&
                                                        categoryType !==
                                                            "contractPrice")
                                                        ? "text.disabled"
                                                        : "text.primary"
                                                }
                                            >
                                                Fixed price
                                            </Typography>
                                        }
                                        disabled={
                                            (!!brands && !brand?.brandId) ||
                                            (!!categoryType &&
                                                categoryType !==
                                                    "contractPrice")
                                        }
                                    />
                                )}

                                {canAddDiscountPercentage && (
                                    <FormControlLabel
                                        value="discountPercentage"
                                        control={<Radio size="small" />}
                                        label={
                                            <Typography
                                                variant="body2"
                                                color={
                                                    (!!brands &&
                                                        !brand?.brandId) ||
                                                    (!!categoryType &&
                                                        categoryType !==
                                                            "discountPercentage")
                                                        ? "text.disabled"
                                                        : "text.primary"
                                                }
                                            >
                                                Fixed discount
                                            </Typography>
                                        }
                                        disabled={
                                            (!!brands && !brand?.brandId) ||
                                            (!!categoryType &&
                                                categoryType !==
                                                    "discountPercentage")
                                        }
                                    />
                                )}

                                {canAddScales && (
                                    <FormControlLabel
                                        value="scale"
                                        disabled={!!brands && !brand?.brandId}
                                        control={<Radio size="small" />}
                                        label={
                                            <Typography
                                                variant="body2"
                                                color={
                                                    !!brands && !brand?.brandId
                                                        ? "text.disabled"
                                                        : "text.primary"
                                                }
                                            >
                                                Scale
                                            </Typography>
                                        }
                                    />
                                )}
                            </RadioGroup>
                        </FormControl>
                    )}
                </Grid>

                {(canAddDiscountPercentage || canAddFixedPrice) &&
                    (type === "discountPercentage" ||
                        type === "contractPrice") && (
                        <Grid item xs={12} md={6}>
                            <NumericFormat
                                id={`${id}-rebate-at-infusion-amount`}
                                name={"amount"}
                                className={style.whiteInput}
                                value={amount}
                                autoComplete="off"
                                error={
                                    (!!errors?.amount && !!touched?.amount) ||
                                    typeError?.type === "discountPercentage" ||
                                    typeError?.type === "contractPrice"
                                }
                                disabled={!!brands && !brand?.brandId}
                                thousandSeparator={
                                    separators.thousandsSeparator
                                }
                                fullWidth
                                decimalSeparator={separators.decimalSeparator}
                                customInput={TextField}
                                allowNegative={false}
                                size="small"
                                isAllowed={values => {
                                    const { floatValue } = values;
                                    return !!floatValue &&
                                        type === "discountPercentage"
                                        ? floatValue <= 100
                                        : true;
                                }}
                                onValueChange={({ floatValue }) => {
                                    handleChange(
                                        "amount",
                                        floatValue?.toString() ?? "",
                                    );
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography variant="caption1">
                                                {type === "discountPercentage"
                                                    ? "%"
                                                    : currency}
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {typeError?.error && (
                                <FormHelperText error>
                                    {typeError.error}
                                </FormHelperText>
                            )}
                        </Grid>
                    )}
            </Grid>
            {canAddScales && type === "scale" && !!currency && (
                <AdvancedProductScales
                    id={"edit-existing-scale"}
                    scales={scales as Array<AdvancedScale>}
                    handleScalesChange={handleProductScales}
                    allowedScaleRange={allowedScaleRange}
                    categoryType={categoryType as string}
                    currency={currency}
                />
            )}
        </Fragment>
    );
};

export default PricingOptionSelector;
