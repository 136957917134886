import { BPFPaymentInformation } from "@types";
import { atom } from "recoil";

/**
 * BPF General Information State
 */
export const BPFPaymentInformationState = atom({
    key: "BPFPaymentInformationState",
    default: {} as BPFPaymentInformation,
});
