// Lib
import { Box, Typography } from "@mui/material";

import React, {
    Fragment,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import clonedeep from "lodash.clonedeep";

// Own components
import { GrayBox } from "@components";

// Hooks
import { useUpdateStep } from "@hooks";

// Utils
import { isArrayWithContent } from "@utils";
import {
    selectContractsState,
    selectOutcomesState,
    BPFPaymentInformationState,
} from "@atoms";
import { useRecoilState, useResetRecoilState } from "recoil";
import AssignOutcomesModal from "./AssignOutcomesModal";

/**
 * Props type
 */
interface Props {
    onChange?: (key: string, value: any) => void;
    contractId?: string;
    flowState?: string;
    location?: Location;
    resetBpfStatePartially?: () => void;
}

/**
 * Select Outcomes
 */
const SelectOutcomes = React.forwardRef(
    ({ flowState, location, resetBpfStatePartially }: Props, ref) => {
        /**
         * Assigned contract
         */
        const [assignedContract, setContract] = useState<string>("");

        /**
         * Update step validation
         */
        const updateStepValidation = useUpdateStep(location, flowState);

        /**
         * Reset BPF Payment Information State
         */
        const resetBPFPaymentInformationState = useResetRecoilState(
            BPFPaymentInformationState,
        );

        /**
         * Selected contracts
         */
        const [selectedContracts, updateContracts] =
            useRecoilState(selectContractsState);

        /**
         * Selected outcomes
         */
        const [selectedOutcomes, updateOutcomes] =
            useRecoilState(selectOutcomesState);

        /**
         * Effect to map selected contracts
         */
        useEffect(() => {
            if (isArrayWithContent(selectedContracts)) {
                const mappedOutcomes = selectedContracts.map(item => {
                    const existingOutcome = selectedOutcomes.find(
                        outcome => outcome.contractId === item.id,
                    );

                    if (existingOutcome) {
                        return existingOutcome;
                    }
                    return {
                        contractId: item.id,
                        reference: item.reference,
                        outcomes: [],
                    };
                });

                updateOutcomes(mappedOutcomes);
            }
        }, [selectedContracts]);

        /**
         * Select outcomes handler
         */
        const handleSelectOutcomes = (outcomes: string[]) => {
            if (outcomes) {
                const index = selectedOutcomes.findIndex(
                    item => item.contractId === assignedContract,
                );
                if (index !== -1) {
                    const clonedOutcomes = clonedeep(selectedOutcomes);
                    clonedOutcomes[index] = {
                        ...selectedOutcomes[index],
                        outcomes,
                    };

                    updateOutcomes(clonedOutcomes);
                    setContract("");
                }
            }
        };

        /**
         * pass the state back to the layout / higher order component
         */
        useImperativeHandle(ref, () => ({
            onSubmit() {
                return selectedOutcomes;
            },
        }));

        useEffect(() => {
            if (!isArrayWithContent(selectedContracts)) {
                resetBPFPaymentInformationState();
                !!resetBpfStatePartially && resetBpfStatePartially();
            }
        }, [selectedContracts]);

        /**
         * Check if the current step is valid
         * then set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            if (!isArrayWithContent(selectedContracts)) return;
            const canSaveState = isArrayWithContent(selectedOutcomes);

            updateStepValidation(canSaveState);
        }, [selectedOutcomes, selectedContracts]);

        /**
         * Map selected outcomes
         */
        const mapSelectedOutcomes = useMemo(() => {
            const findContract = selectedOutcomes.find(
                item => item.contractId === assignedContract,
            );

            return findContract?.outcomes || [];
        }, [assignedContract, selectedOutcomes]);

        /**
         * Delete contract
         * @param contractId
         */
        const deleteContract = (contractId: string) => {
            const clonedOutcomes = clonedeep(selectedOutcomes);
            const filteredOutcomes = clonedOutcomes.filter(
                item => item.contractId !== contractId,
            );
            updateOutcomes(filteredOutcomes);
            const filteredContracts = selectedContracts.filter(
                item => item.id !== contractId,
            );
            updateContracts(filteredContracts);
        };

        /**
         * Render
         */
        return (
            <Fragment>
                <Typography variant="h2" mb={1}>
                    Select outcomes
                </Typography>

                <AssignOutcomesModal
                    contractId={assignedContract}
                    title="Assign Outcomes"
                    onSave={handleSelectOutcomes}
                    onClose={() => {
                        setContract("");
                    }}
                    selectedOutcomes={mapSelectedOutcomes}
                />

                {selectedOutcomes?.map(item => (
                    <GrayBox
                        id={`${item.contractId}-gray-box`}
                        key={item.contractId}
                        primaryAction={{
                            text: item?.outcomes?.length === 0 ? "Add" : "Edit",
                            onClick: () => {
                                setContract(item?.contractId);
                            },
                        }}
                        marginY={2}
                        secondaryAction={{
                            text: "Delete",
                            onClick: () => {
                                deleteContract(item?.contractId);
                            },
                        }}
                        header={
                            <Typography variant="h3">
                                {item.reference}
                            </Typography>
                        }
                    >
                        <Box display="flex" flexDirection="column">
                            <Typography
                                variant="captionSmall"
                                color={
                                    item?.outcomes?.length === 0
                                        ? "error"
                                        : "primary"
                                }
                            >
                                {`${item?.outcomes?.length === 0 ? "There are no outcomes assigned to this contract" : `There ${item?.outcomes?.length === 1 ? "is" : "are"} ${item?.outcomes?.length} outcome${item?.outcomes?.length === 1 ? "" : "s"} assigned to this contract`}`}
                            </Typography>
                        </Box>
                    </GrayBox>
                ))}
            </Fragment>
        );
    },
);
export default SelectOutcomes;
