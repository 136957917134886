import { AdvancedScale } from "@types";
import { isArrayWithContent, thousandsSeparator } from "@utils";

type Product = {
    contractPrice: null | string;
    currency: string;
    discountPercentage: null | string;
    externalProductCode: string | undefined;
    priceCondition: { price: string };
    productId: string;
    productName: string;
    sapSkuNo: string;
    scales: Array<AdvancedScale>;
};

/**
 * Maps price
 * @param amount
 * @param currency
 * @param needsSeparator
 * @returns string
 */
const mapPrice = (
    amount: string | number | undefined,
    currency?: string,
): string => {
    if (!amount) return "";
    return currency
        ? `${thousandsSeparator(amount)} ${currency}`
        : String(amount);
};

/**
 * Maps contract subheader
 * @param product
 * @param currency
 * @param needsSeparator
 * @returns Array of strings
 */
export const getContractSubheader = (
    product: Product & {
        product?: { sapSkuNo?: string };
        type?: "discountPercentage" | "contractPrice" | "scale";
        amount?: string | number;
        priceSource?: string;
        priceCondition: string | { priceCondition: string; price: string };
    },
    currency?: string,
) => {
    const sapSkuNo = product?.sapSkuNo || product?.product?.sapSkuNo;
    const priceCondition =
        typeof product?.priceCondition === "string"
            ? product?.priceCondition
            : product?.priceCondition?.priceCondition;
    const price = product?.priceCondition?.price;

    const priceSource = product?.priceSource;

    const discount =
        product?.type === "discountPercentage" || !!product?.discountPercentage
            ? `Fixed discount: ${product?.discountPercentage || product?.amount}%`
            : "";

    const contractPriceAmount = product?.contractPrice || product?.amount;

    const contractPrice =
        product?.type === "contractPrice" || !!product?.contractPrice
            ? `Fixed price: ${mapPrice(contractPriceAmount, currency)}`
            : "";

    const scales =
        product?.type === "scale" || isArrayWithContent(product.scales)
            ? "Scales"
            : "";

    return [
        sapSkuNo,
        priceCondition || priceSource,
        mapPrice(price, currency),
        discount || contractPrice || scales,
    ];
};

type FinancialCondition = {
    fixedRebateAmount?: string;
    selectedCondition?: string;
    rebatePercentage?: number;
    amount?: string;
    name?: string;
};

type FinancialConditions = {
    [key: string]: FinancialCondition;
};

/**
 * Maps financial conditions
 * @param financialConditions
 * @returns
 */
export const mappedFinancialConditions = (
    financialConditions: FinancialConditions,
) => {
    if (!financialConditions || Object.keys(financialConditions).length === 0)
        return;

    const key = Object.keys(financialConditions)[0];

    const isApheresisBased = key === "apheresisVat" || key === "apheresisNoVat";
    const condition =
        financialConditions[key]?.fixedRebateAmount ||
        financialConditions[key]?.selectedCondition === "fixedRebateAmount"
            ? "fixedRebateAmount"
            : "rebatePercentage";
    const amount =
        financialConditions[key][condition] ||
        financialConditions[key]["amount"];

    const name = financialConditions[key]?.name;

    const amountLabel = isApheresisBased ? "Amount (without VAT)" : "Amount";

    return {
        key,
        condition,
        amount,
        name,
        amountLabel,
        isApheresisBased,
    };
};
