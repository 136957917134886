// Lib
import { Box, Typography } from "@mui/material";

import React, { Fragment, useEffect, useState } from "react";

// Own components
import { Table, SearchBar, Modal } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import { useFinance } from "@hooks";

// Types
import { SearchFilters } from "@types";

// Utils
import { apiResponseCounter, constructQueryString } from "@utils";

/**
 * Props type
 */
interface Props {
    disabled?: boolean;
    contractId: string;
    title: string;
    onSave: (outcomes: string[]) => void;
    onClose: () => void;
    selectedOutcomes: string[];
}

/**
 * Assign Outcomes Modal
 */
const AssignOutcomesModal = ({
    disabled,
    contractId,
    title,
    onSave,
    onClose,
    selectedOutcomes,
}: Props) => {
    /**
     * Selected outcomes
     */
    const [outcomes, selectOutcomes] = useState<string[]>([]);

    // Get outcomes
    const {
        list: outcomesList,
        loading: { fetching, creating },
        search: getOutcomes,
    } = useFinance("bpf", "outcome|outcomes", "outcomes");

    /**
     * Select outcomes handler
     */
    const handleSelectOutcomes = (outcomeId: string) => {
        if (!contractId) return;

        const storedOutcomes = outcomes;

        const index = storedOutcomes?.findIndex(
            storedOutcomeId => storedOutcomeId === outcomeId,
        );

        const clonedOutcomes = storedOutcomes ? [...storedOutcomes] : [];

        if (index !== undefined && index > -1) {
            const updatedItems = clonedOutcomes.filter(
                (_, idx) => index !== idx,
            );
            selectOutcomes(updatedItems);
        } else {
            clonedOutcomes.push(outcomeId);
            selectOutcomes(clonedOutcomes);
        }
    };

    useEffect(() => {
        if (selectedOutcomes) {
            selectOutcomes(selectedOutcomes);
        }
    }, [selectedOutcomes]);

    /**
     * Handle search
     */
    const onSearch = (filters: SearchFilters) => {
        const hasFilters = Object.values(filters).some(filter => filter.length);

        const params = constructQueryString(filters);

        if (hasFilters) {
            getOutcomes(`?contractId=${contractId}${params}`);
        } else getOutcomes(`?contractId=${contractId}`);
    };

    /**
     * Render
     */
    return (
        <Modal
            largeView
            id={`${contractId}-modal`}
            open={!!contractId}
            smallView
            onClose={() => {
                onClose();
                selectOutcomes([]);
            }}
            title={title}
            primaryButton={{
                action: () => onSave(outcomes),
                text: "Save",
                loading: fetching || creating,
                disabled: disabled,
                id: `${contractId}-primary-btn`,
            }}
            secondaryButton={{
                action: () => {
                    onClose();
                },
                text: "Cancel",
                id: `${contractId}-secondary-btn`,
            }}
        >
            <Fragment>
                <Box mb={3}>
                    <SearchBar
                        id="dashboard-bpf-outcomes"
                        handleSearch={onSearch}
                        placeholder="Search by supplier or filename..."
                        startDate={false}
                        endDate={false}
                        searchTextOnly
                        simpleLayout
                    />

                    <Box display="flex" alignItems="baseline" mb={4}>
                        <Typography variant="h3">Search results</Typography>

                        <Typography ml={1} variant="caption1">
                            {apiResponseCounter(
                                outcomesList,
                                fetching,
                                "outcome|outcomes",
                            )}
                        </Typography>
                    </Box>

                    <Table
                        id={`generate-bpf-list`}
                        headers={HEADERS.FINANCES_OUTCOMES}
                        rows={outcomesList?.data?.records}
                        loading={fetching}
                        type={ROWRENDERERCONST.FINANCES_OUTCOMES}
                        disabled={creating}
                        callbacks={{
                            onSelect: handleSelectOutcomes,
                            selectedItems: outcomes,
                        }}
                        emptyMsg="No data"
                        maxHeight="38rem"
                        isOverview={false}
                    />
                </Box>
            </Fragment>
        </Modal>
    );
};

export default React.memo(AssignOutcomesModal);
