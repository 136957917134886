export { apiResponseCounter, isSuccessfulCall, startDownload } from "./api";
export {
    isArrayWithContent,
    isObjectWithContent,
    truncateArray,
    sortByField,
} from "./array";
export {
    convertDateForAPI,
    datePlaceholder,
    defaultEndMinDate,
    defaultStartMinDate,
    displayDate,
    setInitialDate,
    isValidDate,
    isValidDateRange,
    generateQuarters,
    calculateAllowedDateRanges,
    subtractWorkingDays,
} from "./date";
export { constructQueryString } from "./queryString";
export { findStep } from "./step-finder";
export {
    stringTransformer,
    truncateString,
    constantMapper,
    camelCaseSplitter,
    trimAndFormat,
    convertBase64,
} from "./string";
export {
    blockInvalidNumber,
    thousandsSeparator,
    mapSeparators,
} from "./number";

export const pathname =
    typeof window !== "undefined" ? window.location.pathname : null;

export { splitStringWithDash } from "./string";

export {
    getContractSubheader,
    mappedFinancialConditions,
} from "./specificContractHelpers";

export { breadcrumbsMapper } from "./breadcrumbsMapper";
