import { atom, selector } from "recoil";

import {
    BPFPaymentInformationState,
    BPFGeneralInformationState,
    selectClaimsState,
    selectOutcomesState,
    selectContractsState,
} from "@atoms";
import { isArrayWithContent } from "@utils";

const mapBPFForApi = ({
    BPFGeneralInformation,
    BPFPaymentInformation,
    selectClaims,
    selectOutcomes,
}: any) => {
    const isVolumeBased = isArrayWithContent(selectClaims);
    return {
        ...BPFGeneralInformation,
        ...BPFPaymentInformation,
        bankType: BPFPaymentInformation?.bankType
            ? Number(BPFPaymentInformation?.bankType)
            : null,
        contracts: isVolumeBased
            ? selectClaims.map(item => {
                  return {
                      claims: item?.claims,
                      priceCorrectionClaims: item?.priceCorrectionClaims,
                      contractId: item?.contractId,
                  };
              })
            : selectOutcomes?.map(item => {
                  return {
                      outcomes: item?.outcomes,
                      contractId: item?.contractId,
                  };
              }),
        contractPartner: undefined, // For UI purpose only
        paymentCondition: undefined, // For UI purpose only
    };
};

// Add this new atom
export const resetBPFTriggerState = atom({
    key: "resetBPFTriggerState",
    default: 0,
});

/**
 * Submit create BPF
 */
export const createBPFMapper = selector({
    key: "createBPFMapper",
    get: ({ get }) => {
        const BPFGeneralInformation = get(BPFGeneralInformationState);
        const BPFPaymentInformation = get(BPFPaymentInformationState);
        const selectClaims = get(selectClaimsState);
        const selectOutcomes = get(selectOutcomesState);
        const selectContracts = get(selectContractsState);

        return mapBPFForApi({
            BPFGeneralInformation,
            BPFPaymentInformation,
            selectClaims,
            selectOutcomes,
            selectContracts,
        });
    },
    set: ({ reset, set }) => {
        reset(BPFGeneralInformationState);
        reset(BPFPaymentInformationState);
        reset(selectClaimsState);
        reset(selectOutcomesState);
        reset(selectContractsState);
        // Increment the trigger to force rerender
        set(resetBPFTriggerState, prev => prev + 1);
    },
});
