//import libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

// import own types
import type { Role } from "@types";

// import own styles
import * as style from "../../style.module.scss";

interface Props {
    row: Role;
    rowIndex: number;
    id: string;
    callbacks: any;
    disabled?: boolean;
}

const MaintenanceRoles = ({
    id,
    row,
    rowIndex,
    callbacks,
    disabled,
}: Props) => {
    return (
        <TableRow id={`${id}-row-${rowIndex}`} className={style.row}>
            <TableCell>
                <Typography variant="caption2">{row.roleName}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.roleCode || "-"}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.approvalLevel || "-"}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row?.country?.isoCode || "-"}
                </Typography>
            </TableCell>

            <TableCell align="right" className={style.action}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-${rowIndex}-task-row`}
                        autoHide
                        options={[
                            {
                                label: "Edit Role",
                                action: () =>
                                    callbacks &&
                                    callbacks?.onEdit(row?.roleId, rowIndex),
                                disabled: disabled,
                            },
                            {
                                label: "Delete Role",
                                action: () =>
                                    callbacks &&
                                    callbacks?.onDelete(row?.roleId, rowIndex),
                                disabled: disabled,
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default MaintenanceRoles;
