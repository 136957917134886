// Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Lib
import {
    Typography,
    InputLabel,
    Grid,
    Box,
    FormHelperText,
    CircularProgress,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { DatePicker } from "@mui/x-date-pickers";
import { useRecoilState, useRecoilValue } from "recoil";

// Hooks
import { useUpdateStep, useBpfPaymentCondition } from "@hooks";

// Atoms
import {
    BPFGeneralInformationState,
    BPFPaymentInformationState,
    selectContractsState,
} from "@atoms";

// Utils
import {
    convertDateForAPI,
    datePlaceholder,
    isArrayWithContent,
    isSuccessfulCall,
    subtractWorkingDays,
} from "@utils";

// Components
import {
    Dropzone,
    FieldRenderer,
    FormFooter,
    InfoBox,
    NumberInput,
    PaymentInformation as PaymentInformationComponent,
} from "@components";

/**
 * Props type
 */
interface Props {
    flowState?: string;
    location?: Location;
}

/**
 * Payment Information
 */
const BPFPaymentInformation = ({ flowState, location }: Props) => {
    const {
        load,
        loading,
        list: paymentConditionsResponse,
    } = useBpfPaymentCondition();

    /**
     * Recoil
     */
    const selectedContracts = useRecoilValue(selectContractsState);

    const [dateErrors, setDateErrors] = useState({
        bpfProcessDate: false,
        paymentDueDate: false,
        bankType: false,
    });

    /**
     * BPF General Information State
     */
    const [values, updateValuesState] = useRecoilState(
        BPFPaymentInformationState,
    );

    /**
     * General Information State
     */
    const generalInformation = useRecoilValue(BPFGeneralInformationState);

    /**
     * Update step validation
     */
    const updateStepValidation = useUpdateStep(location, flowState);

    /**
     * Update recoil state
     */
    const onChange = (key: string | undefined, value: any) => {
        if (!key) {
            updateValuesState(prevValues => ({
                ...prevValues,
                ...value,
            }));
        } else
            updateValuesState(prevValues => ({
                ...prevValues,
                [key]: value,
            }));
    };

    useEffect(() => {
        if (
            !!values?.bankType ||
            !generalInformation?.contractPartner?.bankType
        )
            return;

        if (
            Number(generalInformation?.contractPartner?.bankType) > 10 ||
            Number(generalInformation?.contractPartner?.bankType) < 1
        ) {
            setDateErrors(prev => ({
                ...prev,
                bankType: true,
            }));
        }

        updateValuesState({
            ...values,
            bankType: generalInformation?.contractPartner?.bankType,
        });
    }, [generalInformation]);

    const contractIds = useMemo(() => {
        return selectedContracts.map(contract => contract.id);
    }, [selectedContracts]);

    /**
     * Render
     */
    return (
        <PaymentInformationComponent
            bankType={values?.bankType}
            loadPaymentCondition={load}
            paymentCondition={values?.paymentCondition}
            loadingPaymentCondition={loading}
            handleChange={onChange}
            invoiceDate={values?.invoiceDate}
            paymentDueDate={values?.paymentDueDate}
            bpfProcessDate={values?.bpfProcessDate}
            invoice={values?.invoice}
            setDateErrors={setDateErrors}
            dateErrors={dateErrors}
            updateStepValidation={updateStepValidation}
            contractIds={contractIds}
        />
    );
};

export default React.memo(BPFPaymentInformation);
