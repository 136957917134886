// Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Lib
import {
    Typography,
    InputLabel,
    Grid,
    Box,
    FormHelperText,
    CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { DatePicker } from "@mui/x-date-pickers";

// Utils
import {
    convertDateForAPI,
    datePlaceholder,
    isArrayWithContent,
    subtractWorkingDays,
} from "@utils";

// Components
import {
    Dropzone,
    FieldRenderer,
    FormFooter,
    InfoBox,
    NumberInput,
} from "@components";

/**
 * Props type
 */
interface Props {
    bpfId?: string;
    loadPaymentCondition: (contractIds: string[]) => Promise<void>;
    paymentCondition: any;
    loadingPaymentCondition: boolean;
    handleChange: (key: string | undefined, value: any) => void;
    updateStepValidation?: (value: boolean) => void;
    contractIds: string[];
    title?: string;
    downloadEvidence?: (
        bpfId: string,
        fileName: string,
        type: string,
    ) => Promise<void>;
    bankType?: string;
    originalInvoice?: {
        fileName: string;
    };
    invoiceDate?: string;
    paymentDueDate?: string;
    bpfProcessDate?: string;
    isEditBpf?: boolean;
    invoice?: File | undefined;
    downloadingEvidence?: boolean;
    setDateErrors: (value: any) => void;
    dateErrors: any;
    type?: string;
}

/**
 * Payment Information
 */
const PaymentInformation = ({
    bpfId,
    title,
    loadPaymentCondition,
    paymentCondition,
    loadingPaymentCondition,
    handleChange,
    updateStepValidation,
    contractIds,
    downloadEvidence,
    originalInvoice,
    invoiceDate,
    paymentDueDate,
    bpfProcessDate,
    bankType,
    isEditBpf,
    invoice,
    setDateErrors,
    dateErrors,
    type,
}: Props) => {
    // toucheded
    const [touched, setToucheded] = useState<{
        invoiceDate: boolean;
        paymentDueDate: boolean;
        bpfProcessDate: boolean;
        bankType: boolean;
        invoice: boolean;
    }>({
        invoiceDate: false,
        paymentDueDate: false,
        bpfProcessDate: false,
        bankType: false,
        invoice: false,
    });

    useEffect(() => {
        if (
            !!paymentDueDate ||
            !!touched?.paymentDueDate || // Do not fetch payment condition if payment due date is touched
            !isArrayWithContent(contractIds)
        )
            return;

        if (invoiceDate && paymentCondition) {
            // Calculate payment due date based on payment condition
            const formattedInvoiceDate = DateTime.fromISO(invoiceDate);
            const paymentDueDate = formattedInvoiceDate.plus({
                days: Number(paymentCondition?.paymentConditionInDays),
            });

            handleChange("paymentDueDate", paymentDueDate.toISODate());
        }
    }, [contractIds, paymentDueDate, invoiceDate]);

    useEffect(() => {
        if (
            !!paymentCondition ||
            !!touched?.paymentDueDate || // Do not fetch payment condition if payment due date is touched
            !isArrayWithContent(contractIds)
        )
            return;

        loadPaymentCondition(contractIds).then((response: unknown) => {
            const condition = (
                response as { data?: { paymentConditionInDays?: number } }
            )?.data;

            if (condition) {
                handleChange("paymentCondition", condition);
            }
        });
    }, [contractIds, paymentCondition]);

    /**
     * Update recoil state
     */
    const onChange = (key: string, value: any) => {
        if (!key) return;

        handleChange(key, value);
    };

    useEffect(() => {
        if (!!paymentDueDate && !!bpfProcessDate) return;

        // Only calculate bpfProcessDate if both invoiceDate and paymentDueDate exist
        if (!!invoiceDate && !!paymentDueDate) {
            const processDate = subtractWorkingDays(
                DateTime.fromISO(paymentDueDate),
                7,
            );
            handleChange("bpfProcessDate", processDate.toISODate());
        }
    }, [paymentDueDate]);

    /**
     * Check if the current step is valid
     * then set isPrepared to true, otherwise set it false
     */
    useEffect(() => {
        if (!updateStepValidation) return;

        const canSaveState =
            !!invoiceDate &&
            !!paymentDueDate &&
            !!bpfProcessDate &&
            !!bankType &&
            (isEditBpf ? !!originalInvoice : !!invoice) &&
            !dateErrors.paymentDueDate &&
            !dateErrors.bpfProcessDate &&
            !dateErrors.bankType;

        updateStepValidation(canSaveState);
    }, [
        dateErrors,
        invoiceDate,
        paymentDueDate,
        bpfProcessDate,
        bankType,
        isEditBpf,
        originalInvoice,
        invoice,
    ]);

    // Update useEffect for payment due date validation
    useEffect(() => {
        if (!invoiceDate || !paymentDueDate) return;

        const paymentDueDateTime =
            DateTime.fromISO(paymentDueDate).startOf("day");
        const invoiceDateTime = DateTime.fromISO(invoiceDate).startOf("day");
        const minPaymentDate = invoiceDateTime.plus({ days: 1 });

        if (paymentDueDateTime < minPaymentDate) {
            setDateErrors(prev => ({
                ...prev,
                paymentDueDate: true,
            }));
        } else {
            setDateErrors(prev => ({
                ...prev,
                paymentDueDate: false,
            }));
        }
    }, [paymentDueDate, invoiceDate]);

    // Add new useEffect for BPF process date validation
    useEffect(() => {
        if (!bpfProcessDate || (!paymentDueDate && !invoiceDate)) return;

        const bpfDateTime = DateTime.fromISO(bpfProcessDate).startOf("day");
        const paymentDueDateTime = paymentDueDate
            ? DateTime.fromISO(paymentDueDate).startOf("day")
            : null;
        const invoiceDateTime = invoiceDate
            ? DateTime.fromISO(invoiceDate).startOf("day")
            : null;

        if (
            (paymentDueDateTime && bpfDateTime > paymentDueDateTime) ||
            (invoiceDateTime && bpfDateTime <= invoiceDateTime)
        ) {
            setDateErrors(prev => ({
                ...prev,
                bpfProcessDate: true,
            }));
        } else {
            setDateErrors(prev => ({
                ...prev,
                bpfProcessDate: false,
            }));
        }
    }, [bpfProcessDate, paymentDueDate, invoiceDate]);

    /**
     * Render
     */
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height={1}
        >
            <div>
                <Typography variant="h2" mb={1}>
                    {title || "Payment information"}
                </Typography>
                <Grid container mt={3} mb={7} spacing={3}>
                    <Grid item xs={6}>
                        <FieldRenderer
                            id={`payment-condition`}
                            label="Payment condition"
                            value={paymentCondition?.paymentCondition}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel
                            id={`invoice-date-label`}
                            shrink
                            error={!invoiceDate && touched["invoiceDate"]}
                        >
                            {"Invoice date (*)"}
                        </InputLabel>

                        <DatePicker
                            value={datePlaceholder(invoiceDate)}
                            onChange={value => {
                                onChange(
                                    "invoiceDate",
                                    !value ||
                                        !value?.isValid ||
                                        value?.year < 1000 ||
                                        value?.year > 9999
                                        ? ""
                                        : convertDateForAPI(value),
                                );
                            }}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    sx: {
                                        svg: {
                                            color: "#036",
                                            opacity: "0.5",
                                        },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        !invoiceDate && touched["invoiceDate"],
                                    id: `invoice-date-input`,
                                    name: "invoice-date-input",
                                    onBlur: () => {
                                        setToucheded({
                                            ...touched,
                                            invoiceDate: true,
                                        });
                                    },
                                },
                            }}
                            slots={{
                                openPickerIcon: CalendarTodayIcon,
                            }}
                        />
                        {!invoiceDate && touched?.invoiceDate && (
                            <FormHelperText error>
                                This field is required
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel
                            id={`payment-due-date-label`}
                            shrink
                            error={!paymentDueDate && touched["paymentDueDate"]}
                        >
                            {"Payment due date (*)"}
                        </InputLabel>

                        <DatePicker
                            disabled={!invoiceDate || loadingPaymentCondition}
                            value={datePlaceholder(paymentDueDate)}
                            onChange={value => {
                                if (
                                    !value ||
                                    !value?.isValid ||
                                    value?.year < 1000 ||
                                    value?.year > 9999
                                ) {
                                    onChange("paymentDueDate", "");
                                    return;
                                }
                                onChange(
                                    "paymentDueDate",
                                    convertDateForAPI(value),
                                );
                            }}
                            format="dd/MM/yyyy"
                            loading={loadingPaymentCondition}
                            minDate={
                                invoiceDate
                                    ? DateTime.fromISO(invoiceDate).plus({
                                          days: 1,
                                      })
                                    : undefined
                            }
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    sx: {
                                        svg: {
                                            color: "#036",
                                            opacity: "0.5",
                                        },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        !paymentDueDate &&
                                        touched["paymentDueDate"],
                                    id: `payment-due-date-input`,
                                    name: "payment-due-date-input",
                                    disabled:
                                        !invoiceDate || loadingPaymentCondition,
                                    onBlur: () => {
                                        setToucheded({
                                            ...touched,
                                            paymentDueDate: true,
                                        });
                                    },
                                },
                            }}
                            slots={{
                                openPickerIcon: loadingPaymentCondition
                                    ? () => <CircularProgress size={20} />
                                    : CalendarTodayIcon,
                            }}
                        />
                        {dateErrors.paymentDueDate && (
                            <FormHelperText error>
                                Date must be after the invoice date
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel
                            id={`bpf-process-date-label`}
                            shrink
                            error={!bpfProcessDate && touched["bpfProcessDate"]}
                        >
                            {"BPF processing date (*)"}
                        </InputLabel>

                        <DatePicker
                            value={datePlaceholder(bpfProcessDate)}
                            disabled={
                                (!bpfProcessDate &&
                                    (!paymentDueDate || !invoiceDate)) ||
                                loadingPaymentCondition
                            }
                            maxDate={
                                paymentDueDate
                                    ? DateTime.fromISO(paymentDueDate)
                                    : undefined
                            }
                            shouldDisableDate={date => {
                                if (!paymentDueDate) return false;
                                return (
                                    date.startOf("day") >
                                    DateTime.fromISO(paymentDueDate).startOf(
                                        "day",
                                    )
                                );
                            }}
                            onChange={value => {
                                if (
                                    !value ||
                                    !value?.isValid ||
                                    value?.year < 1000 ||
                                    value?.year > 9999
                                ) {
                                    onChange("bpfProcessDate", "");
                                    return;
                                }
                                onChange(
                                    "bpfProcessDate",
                                    convertDateForAPI(value),
                                );
                            }}
                            minDate={
                                invoiceDate
                                    ? DateTime.fromISO(invoiceDate).plus({
                                          days: 1,
                                      })
                                    : undefined
                            }
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    disabled:
                                        (!bpfProcessDate &&
                                            (!paymentDueDate ||
                                                !invoiceDate)) ||
                                        loadingPaymentCondition,
                                    sx: {
                                        svg: {
                                            color: "#036",
                                            opacity: "0.5",
                                        },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        !bpfProcessDate &&
                                        touched["bpfProcessDate"],
                                    id: `bpf-process-date-input`,
                                    name: "bpf-process-date-input",
                                    onBlur: () => {
                                        setToucheded({
                                            ...touched,
                                            bpfProcessDate: true,
                                        });
                                    },
                                    onKeyDown: e => {
                                        // Prevent manual input
                                        if (
                                            e.key !== "Tab" &&
                                            e.key !== "Backspace" &&
                                            e.key !== "Delete"
                                        ) {
                                            e.preventDefault();
                                        }
                                    },
                                },
                            }}
                            slots={{
                                openPickerIcon: loadingPaymentCondition
                                    ? () => <CircularProgress size={20} />
                                    : CalendarTodayIcon,
                            }}
                        />
                        {dateErrors.bpfProcessDate && (
                            <FormHelperText error>
                                Date must be equal to or before the payment due
                                date
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputLabel
                            shrink
                            error={!!touched?.bankType && dateErrors.bankType}
                        >
                            {"Bank type"}
                        </InputLabel>
                        <NumberInput
                            name="bankType"
                            variant="outlined"
                            fullWidth
                            value={bankType || ""}
                            onBlur={() => {
                                setToucheded({
                                    ...touched,
                                    bankType: true,
                                });
                            }}
                            id={`bankType`}
                            autoComplete="off"
                            error={!bankType && touched["bankType"]}
                            onChange={e => {
                                onChange("bankType", e.target.value);

                                setDateErrors(prev => ({
                                    ...prev,
                                    bankType:
                                        !!e.target.value &&
                                        (Number(e.target.value) > 10 ||
                                            Number(e.target.value) < 1),
                                }));
                            }}
                            size="small"
                        />

                        {!!touched?.bankType && !!dateErrors?.bankType && (
                            <FormHelperText error>
                                Please enter a bank type between 1 and 10
                            </FormHelperText>
                        )}
                    </Grid>

                    {isEditBpf && !!originalInvoice && !!bpfId && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`original-invoice-field`}
                                value={originalInvoice.fileName}
                                hasDeleteAction
                                hasDownloadAction
                                label="Invoice"
                                onDownloadClick={() => {
                                    !!downloadEvidence &&
                                        downloadEvidence(
                                            bpfId,
                                            originalInvoice.fileName,
                                            type || "INVOICE",
                                        );
                                }}
                                onDeleteClick={() => {
                                    onChange("originalInvoice", undefined);
                                }}
                            />
                        </Grid>
                    )}

                    {(!isEditBpf || (isEditBpf && !originalInvoice)) && (
                        <Grid item xs={12} mt={4}>
                            <InputLabel
                                shrink
                                error={!!touched?.invoice && !invoice}
                            >
                                {"Invoice (*)"}
                            </InputLabel>
                            <Dropzone
                                disabled={!!invoice}
                                variant="big"
                                id={"upload-contract-document"}
                                fileTypes={{
                                    "application/pdf": [".pdf"],
                                    "image/png": [".png"],
                                    "image/jpeg": [".jpeg", ".jpg"],
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                        [".docx"],
                                    "application/msword": [".doc"],
                                    "application/vnd.ms-excel": [".xls"],
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                        [".xlsx"],
                                    "application/vnd.ms-outlook": [".msg"],
                                }}
                                files={[invoice]}
                                onUpload={uploadFiles => {
                                    onChange("invoice", uploadFiles);
                                }}
                                onAttachmentRemove={() => {
                                    onChange("invoice", undefined);
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>

            {!!paymentCondition?.warning && (
                <Box mt="3.125rem">
                    <InfoBox
                        id="warning-for-using-strict-payment-condition"
                        warning
                        message={`${paymentCondition?.warning}`}
                    />
                </Box>
            )}

            <FormFooter
                id={`generate-bpf-footer`}
                error={
                    (!!touched?.invoiceDate && !invoiceDate) ||
                    (!!touched?.paymentDueDate && !paymentDueDate) ||
                    (!!touched?.bpfProcessDate && !bpfProcessDate) ||
                    (!!touched?.bankType && dateErrors.bankType)
                }
                textAlign="right"
            />
        </Box>
    );
};

export default PaymentInformation;
