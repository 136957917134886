//import { useApi } from "../api";
import { useEffect, useLayoutEffect } from "react";
import { isArrayWithContent, isSuccessfulCall, sortByField } from "@utils";
import { useAxios } from "./useAxios";
import type { Api } from "@types";

const BASE_URI = `/v0`;

/**
 * Fetch brands list
 */
export const useBrands = (getList = true) => {
    const {
        response: list,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/brands`,
        },
        { errorHandler: "Failed to fetch brands" },
    );

    let sortedBrands = [];
    if (
        !!list &&
        isSuccessfulCall(list?.status) &&
        !!isArrayWithContent(list?.data)
    ) {
        sortedBrands = list.data.sort((a, b) =>
            a.brandName.localeCompare(b.brandName),
        );
    }

    useEffect(() => {
        if (!getList) return;

        let unmounted = false;
        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        load: () => loadList(),
        error,
        loading,
        list: { ...list, data: sortedBrands },
    };
};

/**
 * Fetch indications list
 */
export const useIndications = () => {
    const {
        response: list,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/indications`,
        },
        { errorHandler: "Failed to fetch indications" },
    );

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        load: loadList,
        error,
        loading,
        list: {
            ...list,
            data: sortByField(list?.data, "indicationName"),
        },
    };
};

/**
 * Fetch therapeuticAreas list
 */
export const useTherapeuticAreas = (getList = true) => {
    const {
        response,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/contracts/therapeuticAreas`,
        },
        { errorHandler: "Failed to fetch therapeutic areas" },
    );

    useEffect(() => {
        if (!getList) return;
        loadList();
    }, []);

    return {
        getTherapeuticAreas: (query?: string) =>
            loadList(
                {
                    method: "GET",
                    url: query
                        ? `${BASE_URI}/contracts/therapeuticAreas${query}`
                        : `${BASE_URI}/contracts/therapeuticAreas`,
                },
                { errorHandler: "Failed to fetch therapeutic areas" },
            ),
        error,
        loading,
        response: {
            ...response,
            data: sortByField(response?.data?.records, "therapeuticAreaName"),
        },
    };
};

/**
 * Fetch account types list
 */

export const useAccountTypes = (getList = true) => {
    const {
        response: list,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/accountType`,
        },
        { errorHandler: "Failed to fetch account types" },
    );

    useLayoutEffect(() => {
        if (!getList) return;
        let unmounted = false;

        if (!unmounted) {
            loadList();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    return {
        load: loadList,
        error,
        loading,
        list: {
            ...list,
            data: sortByField(list?.data?.records, "accountTypeName"),
        },
    };
};

/**
 * Fetch partner roles list
 */
export const usePartnerRoles = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getPartnerRoles: () =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/contracts/partnerRoles`,
                },
                { errorHandler: "Failed to fetch partner roles" },
            ),
        error,
        loading,
        response: {
            ...response,
            data: sortByField(response?.data?.partnerRoles, "roleName"),
        },
    };
};

/**
 * Fetch partners list
 * fetch partners in contract (it has contract permissions on BE side)
 * fetch partners (it has no contract permissions on BE side, it should be filtered based on the country)
 */
export const useContractPartners = (isContractPartner = true) => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getPartners: (query?: string, subQuery?: string) =>
            fetch(
                {
                    method: "GET",
                    /* url: query
                        ? `${BASE_URI}/contracts/partners?search=${query}`
                        : `${BASE_URI}/contracts/partners`, */
                    url: isContractPartner
                        ? `${BASE_URI}/contracts/partners?search=${query}${subQuery ? `&${subQuery}` : ""}`
                        : `${BASE_URI}/partners?search=${query}`,
                },
                { errorHandler: "Failed to fetch partners" },
            ),
        error,
        loading,
        response,
    };
};

/**
 * fetch contract products list
 */
export const useContractsProducts = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getContractsProducts: (query?: string) =>
            fetch(
                {
                    method: "GET",
                    url: query
                        ? `${BASE_URI}/contracts/products${query}`
                        : `${BASE_URI}/contracts/products`,
                },
                { errorHandler: "Failed to fetch products" },
            ),
        error,
        loading,
        response,
    };
};

/**
 * fetch contract products prices list
 */
export const useContractProductPrices = () => {
    const { response, loading, error, fetch }: Api = useAxios();
    return {
        getPrices: (query?: string) =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/contracts/product/prices${query}`,
                },
                { errorHandler: "Failed to fetch product prices" },
            ),
        error,
        loading,
        response,
    };
};

/**
 * fetch admin products list
 */
export const useProducts = (getList = true) => {
    const {
        response,
        loading,
        error,
        fetch: loadList,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/products`,
        },
        { errorHandler: "Failed to fetch products" },
    );

    useEffect(() => {
        if (!getList) return;
        let unmounted = false;
        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        getProducts: (query?: string) =>
            loadList({
                url: query
                    ? `${BASE_URI}/products${query}`
                    : `${BASE_URI}/products`,
            }),
        error,
        loading,
        response,
    };
};

/**
 * fetch admin products prices list
 */
export const useProductPrices = () => {
    const { response, loading, error, fetch }: Api = useAxios();
    return {
        getProductPrices: (productId: string) =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/products/${productId}/productPrices`,
                },
                { errorHandler: "Failed to fetch product prices" },
            ),
        error,
        loading,
        response,
    };
};

/**
 * fetch admin products prices list
 */
export const useUpdateProduct = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        updateProduct: (productId: string, brandId: string) =>
            fetch(
                {
                    method: "PATCH",
                    url: `${BASE_URI}/products/${productId}`,
                    data: { brandId },
                },
                {
                    errorHandler: "Failed to update product",
                    successHandler: "Product was successfully updated",
                },
            ),
        error,
        loading,
        response,
    };
};

/**
 * Create Team
 * Fetch countries
 */
export const useCountries = (getList = true) => {
    const {
        response: list,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/contracts/countries`,
        },
        { errorHandler: "Failed to fetch countries" },
    );
    useEffect(() => {
        if (!getList) return;
        let unmounted = false;

        if (!unmounted) {
            loadList();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    return {
        load: (query?: string) =>
            loadList({
                url: query
                    ? `${BASE_URI}/contracts/countries${query}`
                    : `${BASE_URI}/contracts/countries`,
            }),
        error,
        loading,
        list: {
            ...list,
            data: { records: sortByField(list?.data?.records, "name") },
        },
    };
};

/**
 * Fetch countries limited by the region
 */
export const useTeamCountries = (getList = true) => {
    const url = `${BASE_URI}/user/countries`;
    const {
        response: list,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch countries` },
    );

    useLayoutEffect(() => {
        if (!getList) return;
        let unmounted = false;
        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        load: loadList,
        error,
        loading,
        list: {
            ...list,
            data: { records: sortByField(list?.data?.records, "country") },
        },
        getListByRights: (searchParams?: string) =>
            loadList({
                url: `${url}${searchParams}`,
            }),
    };
};

/**
 * Fetch all countries
 */
export const useAllCountries = (getList = true) => {
    const url = `${BASE_URI}/countries`;
    const {
        response: list,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch countries` },
    );

    useLayoutEffect(() => {
        if (!getList) return;
        let unmounted = false;
        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        load: loadList,
        error,
        loading,
        list: {
            ...list,
            data: { records: sortByField(list?.data?.records, "country") },
        },
    };
};

/**
 * Fetch Therapeutic area limited by the region
 */
export const useTeamTherapeuticArea = () => {
    const {
        response: list,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/user/therapeuticAreas`,
        },
        { errorHandler: `Failed to fetch therapeutic areas` },
    );

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        load: loadList,
        error,
        loading,
        list: {
            ...list,
            data: {
                records: sortByField(
                    list?.data?.records,
                    "therapeuticAreaName",
                ),
            },
        },
    };
};
