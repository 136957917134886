import { Box } from "@mui/material";
import React, {
    Fragment,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { navigate } from "gatsby";

// Own components
import { SearchBar, Table, Dialog } from "@components";

// constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// hooks
import {
    useViewingOptions,
    usePermission,
    useInvoiceRelease,
    useReprocessFinanceCreditNote,
} from "@hooks";

// types
import { SearchFilters, Permissions } from "@types";

// utils
import { constructQueryString, isSuccessfulCall } from "@utils";

/**
 * Props type
 */

type Props = {
    onDataReady?: () => void;
};

interface InvoiceReleaseRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Finances
 */
const InvoiceRelease = React.forwardRef<InvoiceReleaseRef, Props>(
    ({ onDataReady }, ref) => {
        const [outcomeId, setOutcomeId] = useState("");

        /**
         * Hooks
         */
        const { viewingOptions, setViewingOptions } = useViewingOptions(
            ROWRENDERERCONST.INVOICE_RELEASE,
        );

        /**
         * API
         */
        // Get outcomes
        const { getInvoices, loading, response }: any = useInvoiceRelease();
        const {
            reprocessFinanceCreditNote,
            loading: reprocessing,
            response: reprocessResponse,
        }: any = useReprocessFinanceCreditNote(
            "Invoice release|invoice release",
            "OUTCOME",
        );

        /**
         * Permissions
         */
        const {
            hasPermissionToReadFinancialActions,
            hasPermissionToEditFinancialActions,
        }: Permissions = usePermission();

        /**
         * Search handler
         */
        const onSearch = (searchFilters: SearchFilters) => {
            const hasFilters = Object.values(searchFilters).some(
                filter => filter.length,
            );

            const params = constructQueryString(searchFilters, true);

            if (hasFilters) {
                getInvoices(params);
            } else getInvoices();
        };

        useEffect(() => {
            if (!reprocessing && !reprocessResponse) return;
            if (!reprocessing && isSuccessfulCall(reprocessResponse?.status)) {
                setOutcomeId("");
            }
        }, [reprocessing, reprocessResponse]);

        /**
         * Data ready
         */
        useEffect(() => {
            if (response?.data?.records && !loading) {
                onDataReady?.();
            }
        }, [response?.data?.records, loading]);

        /**
         * Imperative handle
         */
        useImperativeHandle(ref, () => ({
            getRecordsCount: () => ({
                itemCount:
                    response?.data?.itemCount ||
                    response?.data?.records?.length,
            }),
        }));
        /**
         * Render
         */
        return (
            <Fragment>
                <Dialog
                    id={`dashboard-invoice-release-dialog`}
                    title={"Reprocess invoice release"}
                    open={!!outcomeId}
                    message={
                        "Are you sure you would like to reprocess the outcome? it could lead to a extra payment."
                    }
                    primaryButton={{
                        text: "Confirm",
                        action: () => reprocessFinanceCreditNote(outcomeId),
                        loading: reprocessing,
                    }}
                    secondaryButton={{
                        text: "Cancel",
                        action: () => setOutcomeId(""),
                    }}
                />

                <Box mt={4} display="flex" justifyContent="space-between">
                    <SearchBar
                        id={`dashboard-invoice-release-overview`}
                        filterKey={ROWRENDERERCONST.INVOICE_RELEASE}
                        viewingOptions={viewingOptions}
                        setViewingOptions={setViewingOptions}
                        handleSearch={(filters: SearchFilters) =>
                            onSearch(filters)
                        }
                        searchTextOnly
                        placeholder="Search by contract reference or cquence id"
                    />
                </Box>

                <Table
                    id={`dashboard-invoice-release-list`}
                    headers={HEADERS.INVOICE_RELEASE}
                    rows={response?.data?.records}
                    loading={loading}
                    type={ROWRENDERERCONST.INVOICE_RELEASE}
                    viewingOptions={viewingOptions}
                    callbacks={{
                        reprocessInvoice: (id: string) => setOutcomeId(id),
                        viewOrderDetails: (id: string) =>
                            navigate(
                                `/finances/invoice-release-details/${id}/`,
                            ),
                    }}
                    permissions={{
                        hasPermissionToReadFinancialActions,
                        hasPermissionToEditFinancialActions,
                    }}
                    emptyMsg="No data"
                />
            </Fragment>
        );
    },
);
export default InvoiceRelease;
