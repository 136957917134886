export const BPF_STATUS = [
    { label: "New", value: "NEW" },
    { label: "Draft", value: "DRAFT" },
    { label: "BPF Sent", value: "BPF_SENT" },
    { label: "BPF Generated", value: "BPF_GENERATED" },
    { label: "Processed", value: "PROCESSED" },
    {
        label: "Cancelled without financial impact",
        value: "CANCELLED_WITHOUT_FINANCIAL_IMPACT",
    },
];
