//import libs
import {
    Box,
    TableCell,
    TableRow,
    Typography,
    Tooltip,
    Checkbox,
} from "@mui/material";
import React, { useMemo } from "react";

//utils
import { displayDate, truncateArray, isArrayWithContent } from "@utils";

//style
import * as style from "./style.module.scss";

interface Row {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    reference: string;
    indication: string;
    products: [
        {
            name: string;
        },
    ];
    primaryContractPartner: { id: string; name: string };
    partners: [
        {
            name: string;
        },
    ];
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
    disabled?: boolean;
}

const BPFOutcomeContractSelectionRow = ({
    row,
    rowIndex,
    id,
    callbacks,
    disabled,
}: Props) => {
    /**
     * Get selected items
     */
    const selectedItems = useMemo(() => {
        if (
            !callbacks?.selectedItems ||
            !isArrayWithContent(callbacks?.selectedItems)
        )
            return [];

        return callbacks?.selectedItems;
    }, [callbacks?.selectedItems]);

    /**
     * Check if the item is selected
     * don't return undefined to avoid warnings
     */
    const isItemSelected = useMemo(() => {
        if (!row?.id || !selectedItems || !isArrayWithContent(selectedItems))
            return false;
        return selectedItems?.some(item => item.id === row?.id);
    }, [selectedItems, row?.id]);

    return (
        <TableRow
            className={style.cursor}
            selected={selectedItems?.includes(row.id)}
            id={`${id}-row-${rowIndex}`}
            onClick={event => {
                if (!disabled) {
                    callbacks.onSelect(
                        row,
                        parseInt((event.target as HTMLInputElement).value),
                    );
                }
            }}
        >
            <TableCell width="4.6875rem">
                <Checkbox
                    onChange={event => {
                        callbacks.onSelect(row, parseInt(event.target.value));
                    }}
                    disabled={disabled}
                    checked={!!isItemSelected}
                    size="small"
                    id={`${id}-row-${rowIndex}-outcome-contract`}
                />
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.reference}</Typography>
            </TableCell>
            <TableCell>
                <Typography
                    variant="caption2"
                    className={style.tableCellSpacing}
                >
                    {row?.primaryContractPartner?.name}
                </Typography>
            </TableCell>
            <TableCell>
                {isArrayWithContent(row.partners) ? (
                    <Tooltip
                        arrow
                        placement="top"
                        title={truncateArray(row?.partners, undefined, "name")}
                    >
                        <Typography variant="caption2">
                            {truncateArray(row?.partners, 2, "name")}
                        </Typography>
                    </Tooltip>
                ) : (
                    "-"
                )}
            </TableCell>

            <TableCell>
                <Box display="flex" flexDirection="column">
                    <Typography
                        variant="caption2"
                        className={style.tableCellSpacing}
                    >
                        {Array.isArray(row.products)
                            ? row.products
                                  .filter(
                                      product => Object.keys(product).length,
                                  )
                                  .map(p => p.name)
                                  .join(", ")
                            : "-"}
                    </Typography>
                    <Typography variant="caption2">
                        {row.indication || "-"}
                    </Typography>
                </Box>
            </TableCell>

            <TableCell>
                <Box display="flex" flexDirection="column">
                    <Typography
                        variant="caption2"
                        className={style.tableCellSpacing}
                    >
                        {displayDate(row.startDate)}
                    </Typography>
                    <Typography variant="caption2">
                        {displayDate(row.endDate)}
                    </Typography>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default BPFOutcomeContractSelectionRow;
