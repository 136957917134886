// icon
import { Add } from "@mui/icons-material";

// Lib
import { Box, Typography, Grid, Button } from "@mui/material";
import { useFormik } from "formik";
import React, {
    Fragment,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import clonedeep from "lodash.clonedeep";

// Own components
import {
    AddProduct,
    FormFooter,
    Modal,
    Table,
    FieldRenderer,
    GrayBox,
} from "@components";

// Custom hooks
import {
    useContractsProducts,
    useUpdateStep,
    useFormValidation,
    useContractProductPrices,
    useBrands,
} from "@hooks";

// Atoms
import {
    productsDetailsState,
    generalInformationState,
    selectModelState,
    selectCategoriesState,
} from "@atoms";

// Schemas
import { volumeBasedProductDetailsSchema } from "@schemas";

// Types
import type { Brand, FlowStep, Location, PriceConditionType } from "@types";

// Utils
import {
    constructQueryString,
    getContractSubheader,
    isArrayWithContent,
} from "@utils";

// Constants
import { ROWRENDERERCONST, HEADERS, CATEGORY_TYPE_MAPPER } from "@constants";

/**
 * Props type
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    contractType?: string;
    flowState?: Array<FlowStep>;
}

interface Product {
    productId: string;
    productName: string;
    externalProductCode: string;
    sapSkuNo: string;
    brand: {
        name: string;
        id: string;
    };
}

interface PriceCondition {
    priceCondition: string;
    currency: string;
    price: string;
}
interface InitialProduct {
    product: Product;
    priceSource: string;
    priceCondition: string | PriceCondition;
    scales: any[]; // You can replace 'any' with a more specific type if needed
    amount: string;
    type: string;
    brand: Brand;
}

/**
 * Add Volume Based Product
 */
const AddVolumeBasedProduct = React.forwardRef(
    ({ location, currentStep, flowState }: Props, ref) => {
        const [showPrices, togglePricesModal] = useState({
            productName: undefined,
            sapSkuNo: undefined,
        });

        // Product start
        const [editProduct, setProduct]: any = useState();
        const [selectedBrand, setBrand] = useState<Brand | undefined>(
            undefined,
        );

        /**
         * API
         */
        const {
            getContractsProducts,
            response,
            loading: loadingProducts,
            error: productsErrors,
        } = useContractsProducts();

        const {
            getPrices,
            loading: pricesLoading,
            response: prices,
        } = useContractProductPrices();

        const {
            list: brands,
            loading: loadingBrands,
            error: brandsError,
        } = useBrands();

        /**
         * Atoms
         */

        // Product details state
        const [contractProducts, updateProductDetails] =
            useRecoilState(productsDetailsState);

        // General information
        const generalInformation = useRecoilValue(generalInformationState);

        // Model
        const selectedModel = useRecoilValue(selectModelState);

        // Categories
        const selectedCategories = useRecoilValue(selectCategoriesState);

        /**
         * Category type
         */
        const categoryType = useMemo(() => {
            if (!isArrayWithContent(selectedCategories)) return;

            const foundCat = selectedCategories.find(cat => {
                return CATEGORY_TYPE_MAPPER[
                    cat?.categoryId?.split("_")?.slice(1)?.join("_")
                ];
            });

            if (!foundCat?.categoryId) return;

            return CATEGORY_TYPE_MAPPER[
                foundCat?.categoryId?.split("_")?.slice(1)?.join("_")
            ];
        }, [selectedCategories]);

        /**
         * Hooks
         */

        // Update step hook
        const updateStepValidation = useUpdateStep(location, flowState);

        /**
         * Formik state
         */
        const formik: any = useFormik({
            initialValues: { ...contractProducts },
            validationSchema: volumeBasedProductDetailsSchema,
            enableReinitialize: true,
            validateOnMount: true,
            onSubmit: () => undefined,
        });

        /**
         * Can select price
         * if price source exists & price source is SAP
         *         => user needs to enter it manually
         * if the source is LAUER
         *          => the user can't select any price from price table ( the condition will be set from the model table)
         */
        const canSelectPriceCondition = useMemo(() => {
            return selectedModel?.priceSource === "SAP";
        }, [selectedModel]);

        /**
         * Form validation
         */
        const { isValidForm, hasErrors } = useFormValidation(
            formik.errors,
            formik.touched,
            currentStep,
        );

        /**
         * Form validation check
         */
        const canSaveState = (formik.isValid || isValidForm) && !editProduct;

        /**
         * Check if the user can input values
         */

        const { canAddFixedPrice, canAddDiscountPercentage, canAddScales } =
            useMemo(() => {
                const canAddDiscountPercentage =
                    categoryType === "discountPercentage" &&
                    (selectedModel?.name === "DEVOLVED_NATION_PERCENTAGE" ||
                        (selectedModel?.name !== "DEVOLVED_NATION_PERCENTAGE" &&
                            selectedModel?.priceSource !== "iTENDER"));

                const canAddFixedPrice =
                    categoryType === "contractPrice" &&
                    selectedModel?.priceSource !== "iTENDER";

                const canAddScales =
                    selectedModel?.name !== "DEVOLVED_NATION_PERCENTAGE" &&
                    selectedModel?.priceSource !== "iTENDER";

                return {
                    canAddFixedPrice,
                    canAddDiscountPercentage,
                    canAddScales,
                };
            }, [selectedModel, categoryType]);

        /**
         * Effects
         */
        useEffect(() => {
            if (!generalInformation || !selectedModel || !selectedBrand) return;
            // In order to fetch pzn nr. we have to query productContextId
            const queryContextId = selectedModel
                ? {
                      contextId: selectedModel?.productContext?.id,
                      datamodelId: selectedModel.datamodelId,
                      brandId: selectedBrand?.brandId,
                  }
                : {};

            const query = constructQueryString(
                {
                    countryIsoCode: generalInformation?.country?.isoCode,
                    brandId: generalInformation?.brand?.brandId,
                    ...queryContextId,
                },
                true,
            );

            getContractsProducts(query);
        }, [selectedModel, generalInformation, selectedBrand]);

        /**
         * Initialize product and brand
         * Reset the current product if the user change the brand
         */
        useEffect(() => {
            if (
                !selectedBrand ||
                editProduct?.brand?.brandId === selectedBrand?.brandId
            )
                return; // is already initialized

            const copyProductMapper = clonedeep(editProduct);
            copyProductMapper.product = {};

            setProduct(copyProductMapper);
        }, [selectedBrand]);

        /**
         * Check if the current step is valid
         * => set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            updateStepValidation(canSaveState);
        }, [canSaveState, location]);

        /**
         * Save state and go to the next page (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            updateState() {
                updateProductDetails(formik.values);
            },
        }));

        useEffect(() => {
            if (
                (!!editProduct?.product &&
                    !!showPrices?.productName &&
                    generalInformation) ||
                (!!showPrices?.productName && generalInformation)
            ) {
                const query = constructQueryString(
                    {
                        sapSkuNo: editProduct?.product?.sapSkuNo,
                        pricesFrom: generalInformation.startDate,
                        pricesTo: generalInformation.endDate,
                    },
                    true,
                );
                getPrices(query);
            }
        }, [showPrices]);

        /**
         * Save product
         */
        const onProductSave = () => {
            if (
                !editProduct?.product?.productId ||
                (!editProduct?.priceCondition && !editProduct?.priceSource)
            )
                return;

            const copyProducts = clonedeep(formik.values.products);

            copyProducts[editProduct.index] = {
                product: { ...editProduct.product },
                priceCondition:
                    typeof editProduct?.priceCondition === "string"
                        ? { priceCondition: editProduct?.priceCondition }
                        : editProduct?.priceCondition,
                priceSource: editProduct?.priceSource,
                scales:
                    editProduct.type !== "scale" ? [] : [...editProduct.scales],
                amount: editProduct.type === "scale" ? "" : editProduct.amount,
                type: editProduct.type,
                brand: selectedBrand,
                cumulativePeriodDuration: editProduct.cumulativePeriodDuration,
                cumulativePeriodLength: editProduct.cumulativePeriodLength,
            };
            formik.setValues({ products: copyProducts });
            setProduct(undefined);
            setBrand(undefined);
        };

        /**
         * Delete product
         */
        const onDelete = (index: number) => {
            const copyProducts = [...formik.values.products];
            const filtered = copyProducts.filter((_, idx) => idx !== index);

            formik.setValues({ products: filtered });
            setProduct(undefined);
            setBrand(undefined);
        };

        /**
         * Change price handler
         */
        const handlePriceChange = (value: any) => {
            const copyProduct = clonedeep(editProduct);
            copyProduct["priceCondition"] = value;
            setProduct(copyProduct);
        };

        /**
         * Disable duplicate product
         */
        const disableSelectedProducts = useMemo(() => {
            if (!isArrayWithContent(formik.values?.products)) return [];

            return formik.values?.products?.map(
                (el, idx) =>
                    idx !== editProduct?.index && el?.product?.productId,
            );
        }, [formik.values?.products, editProduct]);

        /**
         * initialize Adding/Editing product
         */
        const onInitializeProduct = (item?: InitialProduct, index?: number) => {
            if (item && index !== undefined) {
                setProduct({
                    index,
                    product: item?.product,
                    priceCondition:
                        (item?.priceCondition as string) ||
                        (item?.priceCondition as PriceCondition)?.priceCondition
                            ? typeof item?.priceCondition === "string"
                                ? { priceCondition: item?.priceCondition }
                                : item?.priceCondition
                            : undefined,
                    priceSource: item?.priceSource,
                    scales: item.scales,
                    amount: item.amount,
                    type: item.type,
                    brand: item.brand,
                });
                setBrand(item.brand);
                return;
            }

            setProduct({
                index: formik.values.products?.length,
                product: {},
                brand: undefined,
                priceSource: selectedModel?.priceSource,
                priceCondition: canSelectPriceCondition ? {} : undefined,
                amount: "",
                type: categoryType || undefined,
                scales: [],
            });
        };

        const getHeader = item => {
            const productName = item?.product?.productName;
            const brandName = generalInformation?.brand?.brandName;

            return (
                <Box display="flex" flexDirection="column">
                    {brandName ? (
                        <Typography variant="h3">{`${productName} (${brandName})`}</Typography>
                    ) : (
                        <Typography variant="h3">{productName}</Typography>
                    )}
                </Box>
            );
        };

        /**
         * Get subheader
         * @param item
         * @returns Array of strings
         */
        const getSubheader = item => {
            return getContractSubheader(item, selectedModel?.currency);
        };

        /**
         * Renders
         */
        return (
            <Fragment>
                <Modal
                    open={!!showPrices?.productName}
                    id={`contracts-products-prices`}
                    title={"Price table"}
                    onClose={() =>
                        togglePricesModal({
                            sapSkuNo: undefined,
                            productName: undefined,
                        })
                    }
                >
                    <div>
                        <Grid container item xs={12} spacing={3} mb={5}>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`dashboard-products-overview-products-prices-product-name`}
                                    label="Product name"
                                    value={showPrices?.productName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`dashboard-products-overview-products-prices-product-sku-number`}
                                    label="SAP SKU number"
                                    value={showPrices?.sapSkuNo}
                                />
                            </Grid>
                        </Grid>

                        <Table
                            headers={HEADERS.PRICES}
                            rows={prices?.data || []}
                            loading={pricesLoading}
                            type={ROWRENDERERCONST.PRICES}
                            id={`products-prices`}
                            emptyMsg={"No data"}
                            callbacks={{
                                selectCondition: (
                                    priceCondition: PriceConditionType,
                                ) => {
                                    handlePriceChange({
                                        priceCondition:
                                            priceCondition?.condition,
                                        currency: priceCondition?.currency,
                                        price: priceCondition?.price,
                                    });

                                    togglePricesModal({
                                        sapSkuNo: undefined,
                                        productName: undefined,
                                    });
                                },
                            }}
                            isOverview={false}
                        />
                    </div>
                </Modal>

                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height={1}
                >
                    <div>
                        <Box mb={7}>
                            <Typography variant="h2">
                                Product details
                            </Typography>
                        </Box>

                        {isArrayWithContent(formik.values?.products) &&
                            formik.values?.products?.map((item, index) => (
                                <Box mt={3} key={index}>
                                    {editProduct?.index === index ? (
                                        <AddProduct
                                            id={"edit-product-form"}
                                            onChange={setProduct}
                                            onDelete={() => onDelete(index)}
                                            onSave={onProductSave}
                                            onBlur={formik.handleBlur}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            product={editProduct}
                                            brands={{
                                                data: brands?.data,
                                                loading: loadingBrands,
                                                error: brandsError,
                                            }}
                                            brand={selectedBrand}
                                            setBrand={setBrand}
                                            products={{
                                                data: response?.data?.records,
                                                loading: loadingProducts,
                                                error: productsErrors,
                                                disabledProducts:
                                                    disableSelectedProducts,
                                            }}
                                            onShowPricesClick={() =>
                                                togglePricesModal({
                                                    productName:
                                                        editProduct?.product
                                                            ?.productName,
                                                    sapSkuNo:
                                                        editProduct?.product
                                                            ?.sapSkuNo,
                                                })
                                            }
                                            canAddFixedPrice={canAddFixedPrice}
                                            canAddScales={canAddScales}
                                            canAddDiscountPercentage={
                                                canAddDiscountPercentage
                                            }
                                            canSelectPriceCondition={
                                                canSelectPriceCondition
                                            }
                                            type={editProduct?.type}
                                            amount={editProduct?.amount}
                                            scales={editProduct?.scales}
                                            isVolumeBasedContract
                                            canAddMultipleProducts
                                            allowedScaleRange={{
                                                periodFrom:
                                                    generalInformation.startDate,
                                                periodTo:
                                                    generalInformation.endDate,
                                            }}
                                            handleProductScales={scales =>
                                                setProduct({
                                                    ...editProduct,
                                                    scales,
                                                })
                                            }
                                            categoryType={categoryType}
                                            productExternalCodeLabel={
                                                selectedModel?.productContext
                                                    ?.name
                                            }
                                            currency={selectedModel?.currency}
                                        />
                                    ) : (
                                        item?.product?.productId && (
                                            <GrayBox
                                                padding="1rem"
                                                id="product-details-general-info-details"
                                                header={getHeader(item)}
                                                subheaderSplitter={getSubheader(
                                                    item,
                                                )}
                                                primaryAction={{
                                                    text: "Edit",
                                                    onClick: () =>
                                                        onInitializeProduct(
                                                            item,
                                                            index,
                                                        ),
                                                    disabled: !!editProduct,
                                                }}
                                            />
                                        )
                                    )}
                                </Box>
                            ))}
                        {formik.values.products?.length ===
                            editProduct?.index && (
                            <Box mt={3}>
                                <AddProduct
                                    id={"new-product-form"}
                                    onChange={setProduct}
                                    onSave={onProductSave}
                                    onBlur={formik.handleBlur}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    product={editProduct}
                                    canAddFixedPrice={canAddFixedPrice}
                                    canAddScales={canAddScales}
                                    canAddDiscountPercentage={
                                        canAddDiscountPercentage
                                    }
                                    canAddMultipleProducts
                                    brands={{
                                        data: brands?.data,
                                        loading: loadingBrands,
                                        error: brandsError,
                                    }}
                                    brand={selectedBrand}
                                    setBrand={setBrand}
                                    products={{
                                        data: response?.data?.records,
                                        loading: loadingProducts,
                                        error: productsErrors,
                                        disabledProducts:
                                            disableSelectedProducts,
                                    }}
                                    onShowPricesClick={() =>
                                        togglePricesModal({
                                            productName:
                                                editProduct?.product
                                                    .productName,
                                            sapSkuNo:
                                                editProduct?.product?.sapSkuNo,
                                        })
                                    }
                                    onDelete={() => {
                                        setProduct(undefined);
                                        setBrand(undefined);
                                    }}
                                    canDelete={true}
                                    type={editProduct?.type}
                                    amount={editProduct?.amount}
                                    scales={editProduct?.scales}
                                    canSelectPriceCondition={
                                        canSelectPriceCondition
                                    }
                                    isVolumeBasedContract
                                    allowedScaleRange={{
                                        periodFrom:
                                            generalInformation.startDate,
                                        periodTo: generalInformation.endDate,
                                    }}
                                    handleProductScales={scales =>
                                        setProduct({
                                            ...editProduct,
                                            scales,
                                        })
                                    }
                                    categoryType={categoryType}
                                    productExternalCodeLabel={
                                        selectedModel?.productContext?.name
                                    }
                                    currency={selectedModel?.currency}
                                />
                            </Box>
                        )}
                        <Box mt={3}>
                            <GrayBox
                                padding={2}
                                marginY={1}
                                id={`add-product-btn`}
                                header={
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => onInitializeProduct()}
                                        startIcon={<Add />}
                                        size="large"
                                        disabled={!!editProduct}
                                    >
                                        <Typography
                                            variant="caption1"
                                            color={
                                                editProduct
                                                    ? "action.disabled"
                                                    : "primary"
                                            }
                                        >
                                            {`Add product`}
                                        </Typography>
                                    </Button>
                                }
                            />
                        </Box>
                    </div>
                    <FormFooter
                        error={hasErrors}
                        textAlign="right"
                        id={"new-product-form-footer"}
                    />
                </Box>
            </Fragment>
        );
    },
);
export default AddVolumeBasedProduct;
