// Libs
import { TableCell, TableRow, Typography, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

// Own components
import { ActionMenu } from "@components";

// Utils
import { displayDate, thousandsSeparator } from "@utils";
import { Delete } from "@mui/icons-material";

type CumulativeScale = {
    cumulativePeriodFrom: string;
    cumulativePeriodTo: string;
    unitOfMeasureFrom: string;
    unitOfMeasureTo: string;
    currency: string;
    price?: string;
    discountPercentage?: string;
};

type AdvancedScaleRow = {
    periodFrom: string;
    periodTo: string;
    data: CumulativeScale[];
};

interface Props {
    row: AdvancedScaleRow;
    rowIndex: number;
    id: string;
    callbacks: any;
}

const AdvancedScalesRow = ({ rowIndex, row, id, callbacks }: Props) => {
    return (
        <>
            <TableRow
                id={`${id}-row-${rowIndex}`}
                sx={{
                    "& td": {
                        borderBottom: row["periodFrom"] && 0,
                    },
                }}
            >
                <TableCell
                    style={{
                        visibility: !row["periodFrom"] ? "hidden" : "visible",
                    }}
                >
                    {!!row["periodFrom"] && (
                        <Typography variant="caption2">
                            {displayDate(row["periodFrom"])}
                        </Typography>
                    )}
                </TableCell>
                <TableCell
                    colSpan={6}
                    style={{
                        visibility: !row["periodTo"] ? "hidden" : "visible",
                    }}
                >
                    {!!row["periodTo"] && (
                        <Typography variant="caption2">
                            {displayDate(row["periodTo"])}
                        </Typography>
                    )}
                </TableCell>

                {!!callbacks && !!callbacks?.onAddCumulativeScale && (
                    <TableCell padding="checkbox">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <ActionMenu
                                id={`${id}-row-${rowIndex}-contract-row`}
                                autoHide
                                autoWidth
                                options={[
                                    {
                                        label: "Add cumulative scale",
                                        icon: <EditIcon fontSize="small" />,
                                        action: () =>
                                            callbacks?.onAddCumulativeScale([
                                                row?.periodFrom,
                                                row.periodTo,
                                            ]),
                                    },
                                    {
                                        label: "Remove cumulative scales",
                                        icon: <Delete fontSize="small" />,
                                        action: () =>
                                            callbacks?.onScaleDelete([
                                                row?.periodFrom,
                                                row.periodTo,
                                            ]),
                                    },
                                ]}
                            />
                        </Box>
                    </TableCell>
                )}
            </TableRow>

            {row.data.map((el, idx) => (
                <TableRow
                    key={`${id}-row-${rowIndex}-${idx}`}
                    id={`${id}-row-${rowIndex}`}
                >
                    <TableCell
                        style={{
                            visibility: "hidden",
                        }}
                    ></TableCell>
                    <TableCell
                        style={{
                            visibility: "hidden",
                        }}
                    ></TableCell>

                    <TableCell>
                        <Typography variant="caption2">
                            {displayDate(el?.cumulativePeriodFrom)}
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography variant="caption2">
                            {displayDate(el?.cumulativePeriodTo)}
                        </Typography>
                    </TableCell>

                    <TableCell>
                        {(el["unitOfMeasureFrom"] as string) && (
                            <Typography variant="caption2">
                                {thousandsSeparator(el["unitOfMeasureFrom"])}
                            </Typography>
                        )}
                    </TableCell>

                    <TableCell>
                        {(el["unitOfMeasureTo"] as string) && (
                            <Typography variant="caption2">
                                {thousandsSeparator(el["unitOfMeasureTo"])}
                            </Typography>
                        )}
                    </TableCell>

                    {el["price"] !== undefined && el["currency"] && (
                        <TableCell>
                            {!!el["price"] && (
                                <Typography variant="caption2">
                                    {`${thousandsSeparator(el["price"])} ${el["currency"] as string}`}
                                </Typography>
                            )}
                        </TableCell>
                    )}

                    {el["discountPercentage"] !== undefined && (
                        <TableCell>
                            {el["discountPercentage"] && (
                                <Typography variant="caption2">
                                    {`${el["discountPercentage"]}%`}
                                </Typography>
                            )}
                        </TableCell>
                    )}

                    <TableCell padding="checkbox"></TableCell>
                </TableRow>
            ))}
        </>
    );
};

export default AdvancedScalesRow;
