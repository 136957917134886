// Lib
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { Fragment, useCallback } from "react";

// Own components
import { FieldRenderer } from "@components";
// Utils
import {
    displayDate,
    isValidDate,
    camelCaseSplitter,
    thousandsSeparator,
} from "@utils";
// Style
import * as style from "./style.module.scss";

/**
 * CompletedTask
 */
type CompletedTask = {
    approved: boolean;
    assigneeFirstName: string;
    assigneeLastName: string;
    category: string;
    completed: string;
    oldInfusionDate: string;
    newInfusionDate: string;
    reasonRejection?: string;
    override?: boolean;
    reasonOverride?: string;
    decisionInfo?: string;
    amount?: string;
    oldAmount?: string;
    newStartDate?: string;
    newEndDate?: string;
    oldStartDate?: string;
    oldEndDate?: string;
    comment?: string | null;
    recalled?: boolean | null;
    taskDefinitionKey?: string;
};

/**
 * Props
 */
interface Props {
    product?: string;
    therapeuticArea?: string;
    contract?: string;
    sapSkuNo?: string;
    creationDate?: string;
    dueDate?: string;
    title?: string;
    inputValues?: any;
    outcomeValues?: any;
    id: string;
    infusionDate?: string;
    treatmentSite?: string;
    sapOrderNo?: string;
    completedTask?: CompletedTask;
    salessystemOrderId?: string;
    downloadApheresis?: {
        name: string;
        onDownload: () => void;
        loading: boolean;
    } | null;
}

/**
 * Task Review
 */
const TaskReview: React.FC<Props> = ({
    product,
    therapeuticArea,
    contract,
    sapSkuNo,
    creationDate,
    dueDate,
    title,
    outcomeValues,
    inputValues,
    id,
    treatmentSite,
    infusionDate,
    sapOrderNo,
    completedTask,
    salessystemOrderId,
    downloadApheresis,
}) => {
    /**
     * Check if the value is boolean => parse it to yes or no
     *  if the value is date or Date (Camunda sends 2 types) => parse it to dd/mm/yyyy format
     *  otherwise return the value
     */
    const valueTransformer = useCallback(
        (type: string, value: string | boolean) => {
            if (
                typeof value === "boolean" &&
                type?.toLowerCase() === "boolean"
            ) {
                return value === true ? "Yes" : "No";
            } else if (
                type?.toLowerCase() === "date" &&
                typeof value === "string" &&
                isValidDate(value)
            ) {
                return displayDate(value);
            } else return value;
        },
        [],
    );

    /**
     * Render
     */
    return (
        <Box display="flex" flexDirection="column" className={style.wrapper}>
            <Box className={style.card}>
                {title && (
                    <Box mb={4}>
                        <Typography variant="h3">{title}</Typography>
                    </Box>
                )}

                <Box>
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        {product && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-review-product`}
                                    label="Product"
                                    value={product}
                                />
                            </Grid>
                        )}

                        {therapeuticArea && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-review-therapeutic-area`}
                                    label="Therapeutic area"
                                    value={therapeuticArea}
                                />
                            </Grid>
                        )}

                        {contract && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-review-contract`}
                                    label="Contract"
                                    value={contract}
                                />
                            </Grid>
                        )}

                        {treatmentSite && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-treatment-site`}
                                    label="Treatment site"
                                    value={treatmentSite}
                                />
                            </Grid>
                        )}
                        {infusionDate && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-review-infusion-date`}
                                    label="Infusion date"
                                    value={displayDate(infusionDate)}
                                />
                            </Grid>
                        )}

                        {sapOrderNo && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-review-sap-order-number`}
                                    label="SAP Order number"
                                    value={sapOrderNo}
                                />
                            </Grid>
                        )}
                        {sapSkuNo && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-review-sap-code`}
                                    label="SAP code"
                                    value={sapSkuNo}
                                />
                            </Grid>
                        )}
                        {salessystemOrderId && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-review-sap-order-number`}
                                    label="Cquence Id"
                                    value={salessystemOrderId}
                                />
                            </Grid>
                        )}

                        {creationDate && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-review-creation-date`}
                                    label="Creation date"
                                    value={displayDate(creationDate)}
                                />
                            </Grid>
                        )}
                        {dueDate && (
                            <Grid item xs={12} md={3}>
                                <FieldRenderer
                                    id={`${id}-task-review-due-date`}
                                    label="Due date"
                                    value={displayDate(dueDate)}
                                />
                            </Grid>
                        )}
                        {!!inputValues &&
                            Object.keys(inputValues).map((key, index) => {
                                return (
                                    inputValues[key]?.value !== undefined && (
                                        <Grid
                                            item
                                            xs={12}
                                            md={3}
                                            key={`${key}-${index}`}
                                        >
                                            <FieldRenderer
                                                id={`${id}-task-review-${key}-${index}`}
                                                label={inputValues[key]?.label}
                                                value={
                                                    valueTransformer(
                                                        inputValues[key]?.type,
                                                        key === "totalAmount"
                                                            ? thousandsSeparator(
                                                                  inputValues[
                                                                      key
                                                                  ]?.value,
                                                              )
                                                            : inputValues[key]
                                                                  ?.value,
                                                    ) as string
                                                }
                                            />
                                        </Grid>
                                    )
                                );
                            })}
                        {!!outcomeValues && (
                            <Fragment>
                                {outcomeValues.action && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-action`}
                                            label={"Action"}
                                            value={outcomeValues.action}
                                        />
                                    </Grid>
                                )}

                                {outcomeValues.rebateType && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-rebateType`}
                                            label={"Rebate Type"}
                                            value={outcomeValues.rebateType}
                                        />
                                    </Grid>
                                )}
                                {!!outcomeValues?.amount && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-rebateAmount`}
                                            label={"Rebate amount"}
                                            value={thousandsSeparator(
                                                outcomeValues.amount,
                                            )}
                                        />
                                    </Grid>
                                )}

                                {outcomeValues?.percentage && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-rebatePercentage`}
                                            label={"Rebate percentage"}
                                            value={`${outcomeValues?.percentage}%`}
                                        />
                                    </Grid>
                                )}

                                {outcomeValues?.vatAmount && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-vatAmount`}
                                            label={"VAT amount"}
                                            value={thousandsSeparator(
                                                outcomeValues.vatAmount,
                                            )}
                                        />
                                    </Grid>
                                )}

                                {outcomeValues?.vatPercentage && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-vatPercentage`}
                                            label={"VAT percentage"}
                                            value={`${outcomeValues?.vatPercentage}%`}
                                        />
                                    </Grid>
                                )}

                                {outcomeValues?.financialTriggerType && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-type`}
                                            label={"Type"}
                                            value={
                                                outcomeValues?.financialTriggerType
                                                    ? camelCaseSplitter(
                                                          outcomeValues?.financialTriggerType,
                                                      )
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                )}

                                {outcomeValues?.measure && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-measure`}
                                            label={"Measure"}
                                            value={outcomeValues.measure}
                                        />
                                    </Grid>
                                )}

                                {outcomeValues?.daysBetweenInfusionDateAndEndOfTreatmentDate && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-outcome-daysBetweenInfusionDateAndEndOfTreatmentDate`}
                                            label={
                                                "Infusion - Treatment end time"
                                            }
                                            value={`${outcomeValues.daysBetweenInfusionDateAndEndOfTreatmentDate} days`}
                                        />
                                    </Grid>
                                )}

                                {!!outcomeValues?.milestone?.milestoneNo && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-milestoneNo`}
                                            label={"Milestone number"}
                                            value={
                                                outcomeValues?.milestone
                                                    ?.milestoneNo
                                            }
                                        />
                                    </Grid>
                                )}

                                {outcomeValues?.milestone?.milestoneMet !==
                                    undefined &&
                                    outcomeValues?.milestone?.milestoneMet !==
                                        null && (
                                        <Grid item xs={12} md={3}>
                                            <FieldRenderer
                                                id={`${id}-task-review-milestoneMet`}
                                                label={"Milestone met"}
                                                value={
                                                    outcomeValues?.milestone
                                                        ?.milestoneMet === true
                                                        ? "Yes"
                                                        : "No"
                                                }
                                            />
                                        </Grid>
                                    )}

                                {!!outcomeValues?.milestone?.milestoneDescr && (
                                    <Grid item xs={12}>
                                        <FieldRenderer
                                            multiline
                                            id={`${id}-task-review-description`}
                                            label={"Milestone description"}
                                            value={
                                                outcomeValues?.milestone
                                                    ?.milestoneDescr || ""
                                            }
                                        />
                                    </Grid>
                                )}

                                {outcomeValues.comment && (
                                    <Grid item xs={12}>
                                        <FieldRenderer
                                            id={`${id}-task-review-comment`}
                                            label={"Comment"}
                                            multiline
                                            value={outcomeValues.comment || ""}
                                        />
                                    </Grid>
                                )}
                            </Fragment>
                        )}

                        {downloadApheresis?.name &&
                            !!downloadApheresis.onDownload && (
                                <Grid item xs={12} md={3}>
                                    <FieldRenderer
                                        id={`${id}-apheresis-validation-report`}
                                        label={"Apheresis validation report"}
                                        value={downloadApheresis?.name}
                                        loading={downloadApheresis?.loading}
                                        hasDownloadAction
                                        onDownloadClick={() =>
                                            downloadApheresis?.onDownload()
                                        }
                                    />
                                </Grid>
                            )}
                        {outcomeValues?.reasonCancellation && (
                            <Grid item xs={12}>
                                <FieldRenderer
                                    id={`${id}-task-outcome-reasonCancellation`}
                                    label={"Reason of cancellation"}
                                    value={
                                        outcomeValues.reasonCancellation || ""
                                    }
                                    multiline
                                />
                            </Grid>
                        )}

                        {!!completedTask && (
                            <Fragment>
                                <Grid item xs={12} md={3}>
                                    <FieldRenderer
                                        id={`${id}-task-review-completed-tasks-is-completed`}
                                        label={"Completed at"}
                                        value={displayDate(
                                            completedTask.completed,
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FieldRenderer
                                        id={`${id}-task-review-assignee`}
                                        label={"Assignee"}
                                        value={
                                            !!completedTask.assigneeFirstName &&
                                            !!completedTask.assigneeLastName
                                                ? `${completedTask.assigneeFirstName} ${completedTask.assigneeLastName}`
                                                : ""
                                        }
                                    />
                                </Grid>

                                {typeof completedTask.approved ===
                                    "boolean" && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-task-review-approved`}
                                            label={"Approved"}
                                            value={
                                                completedTask.approved
                                                    ? "Yes"
                                                    : `No ${(completedTask?.taskDefinitionKey === "claimApprovalTask" || completedTask?.taskDefinitionKey === "claimOverrideApprovalTask" || completedTask?.taskDefinitionKey === "bpfApprovalTask") && !!completedTask?.recalled ? "(Recalled)" : ""}`
                                            }
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12} md={3}>
                                    <FieldRenderer
                                        id={`${id}-task-review-category`}
                                        label={"Category"}
                                        value={completedTask.category}
                                    />
                                </Grid>

                                {!!completedTask?.oldInfusionDate && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-old-infusion-date`}
                                            label={"Old infusion date"}
                                            value={displayDate(
                                                completedTask?.oldInfusionDate,
                                            )}
                                        />
                                    </Grid>
                                )}

                                {!!completedTask?.newInfusionDate && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-new-infusion-date`}
                                            label={"New infusion date"}
                                            value={displayDate(
                                                completedTask?.newInfusionDate,
                                            )}
                                        />
                                    </Grid>
                                )}

                                {!!completedTask?.oldStartDate && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-old-start-date`}
                                            label={"Old start date"}
                                            value={displayDate(
                                                completedTask?.oldStartDate,
                                            )}
                                        />
                                    </Grid>
                                )}
                                {!!completedTask?.oldEndDate && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-old-end-date`}
                                            label={"Old end date"}
                                            value={displayDate(
                                                completedTask?.oldEndDate,
                                            )}
                                        />
                                    </Grid>
                                )}

                                {!!completedTask?.newStartDate && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-new-start-date`}
                                            label={"New start date"}
                                            value={displayDate(
                                                completedTask?.newStartDate,
                                            )}
                                        />
                                    </Grid>
                                )}

                                {!!completedTask?.newEndDate && (
                                    <Grid item xs={12} md={3}>
                                        <FieldRenderer
                                            id={`${id}-new-end-date`}
                                            label={"New end date"}
                                            value={displayDate(
                                                completedTask?.newEndDate,
                                            )}
                                        />
                                    </Grid>
                                )}

                                {!!completedTask?.comment && (
                                    <Grid item xs={12}>
                                        <FieldRenderer
                                            id={`${id}-comment`}
                                            label={"comment"}
                                            value={completedTask.comment}
                                            multiline
                                        />
                                    </Grid>
                                )}

                                {completedTask?.override === true &&
                                    !!completedTask?.oldAmount &&
                                    completedTask?.amount && (
                                        <>
                                            <Grid item xs={12} md={3}>
                                                <FieldRenderer
                                                    id={`${id}-old-amount`}
                                                    label={"Old rebate amount"}
                                                    value={
                                                        completedTask?.oldAmount
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3}>
                                                <FieldRenderer
                                                    id={`${id}-new-amount`}
                                                    label={"New rebate amount"}
                                                    value={
                                                        completedTask?.amount
                                                    }
                                                />
                                            </Grid>
                                        </>
                                    )}

                                {!!completedTask?.reasonRejection &&
                                    completedTask.approved === false && (
                                        <Grid item xs={12}>
                                            <FieldRenderer
                                                id={`${id}-reason-of-rejection`}
                                                label={"Reason of Rejection"}
                                                value={
                                                    completedTask?.reasonRejection ||
                                                    ""
                                                }
                                                multiline
                                            />
                                        </Grid>
                                    )}
                                {!!completedTask?.reasonOverride &&
                                    completedTask.override === true && (
                                        <Grid item xs={12}>
                                            <FieldRenderer
                                                id={`${id}-reason-of-override`}
                                                label={"Reason for change"}
                                                value={
                                                    completedTask?.reasonOverride ||
                                                    ""
                                                }
                                                multiline
                                            />
                                        </Grid>
                                    )}
                                {!!completedTask?.decisionInfo &&
                                    completedTask.override === true && (
                                        <Grid item xs={12}>
                                            <FieldRenderer
                                                id={`${id}-decisionInfo`}
                                                label={"Additional comments"}
                                                value={
                                                    completedTask?.decisionInfo ||
                                                    ""
                                                }
                                                multiline
                                            />
                                        </Grid>
                                    )}
                            </Fragment>
                        )}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(TaskReview);
