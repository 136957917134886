//import libs
import {
    Box,
    Radio,
    TableCell,
    TableRow,
    Typography,
    Tooltip,
} from "@mui/material";
import React from "react";

//utils
import { displayDate, truncateArray, isArrayWithContent } from "@utils";

//style
import * as style from "./style.module.scss";

interface Row {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    reference: string;
    indication: string;
    products: [
        {
            name: string;
        },
    ];
    primaryContractPartner: { id: string; name: string };
    partners: [
        {
            name: string;
        },
    ];
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
    selectedRow: string;
}

const OutcomeContractSelectionRow = ({
    row,
    rowIndex,
    id,
    callbacks,
    selectedRow,
}: Props) => {
    return (
        <TableRow
            className={style.cursor}
            selected={selectedRow === row.id}
            id={`${id}-row-${rowIndex}`}
            onClick={event => {
                callbacks.onSelect(
                    row,
                    parseInt((event.target as HTMLInputElement).value),
                );
            }}
        >
            <TableCell width="4.6875rem">
                <Radio
                    onChange={event => {
                        callbacks.onSelect(row, parseInt(event.target.value));
                    }}
                    checked={selectedRow === row.id}
                    value={rowIndex}
                    inputProps={{ "aria-label": `${id}-row-${rowIndex}` }}
                    required
                    id={`${id}-row-${rowIndex}-outcome-contract`}
                />
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.reference}</Typography>
            </TableCell>
            <TableCell>
                <Typography
                    variant="caption2"
                    className={style.tableCellSpacing}
                >
                    {row?.primaryContractPartner?.name}
                </Typography>
            </TableCell>
            <TableCell>
                {isArrayWithContent(row.partners) ? (
                    <Tooltip
                        arrow
                        placement="top"
                        title={truncateArray(row?.partners, undefined, "name")}
                    >
                        <Typography variant="caption2">
                            {truncateArray(row?.partners, 2, "name")}
                        </Typography>
                    </Tooltip>
                ) : (
                    "-"
                )}
            </TableCell>

            <TableCell>
                <Box display="flex" flexDirection="column">
                    <Typography
                        variant="caption2"
                        className={style.tableCellSpacing}
                    >
                        {Object.keys(row.products)
                            .map(key => `${row.products[key].name}`)
                            .join(", ")}
                    </Typography>
                    <Typography variant="caption2">{row.indication}</Typography>
                </Box>
            </TableCell>

            <TableCell>
                <Box display="flex" flexDirection="column">
                    <Typography
                        variant="caption2"
                        className={style.tableCellSpacing}
                    >
                        {displayDate(row.startDate)}
                    </Typography>
                    <Typography variant="caption2">
                        {displayDate(row.endDate)}
                    </Typography>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default OutcomeContractSelectionRow;
