//lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFormik, FormikProps } from "formik";
import React, { useEffect, useImperativeHandle, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

// Own components
import { ProductMilestonesForm, FormFooter } from "@components";

// Custom hooks
import { useUpdateStep, useFormValidation } from "@hooks";

// Atoms
import {
    contractMilestonesState,
    createPerformanceBasedContractStepsState,
    generalInformationState,
    productsDetailsState,
    selectModelState,
    financialConditionsState,
} from "@atoms";

// Schemas
import { contractMilestonesSchema } from "@schemas";

// Types
import {
    FlowStep,
    Location,
    ContractMilestones as ContractMilestonesType,
    Brand,
} from "@types";
import { isArrayWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    id?: string;
}

/**
 * Contract Milestones
 */
const ContractMilestones = React.forwardRef(
    ({ location, currentStep, id }: Props, ref) => {
        const financialConditions = useRecoilValue(financialConditionsState);

        /**
         * Get financial conditions
         */
        const canAddMilestones = useMemo(() => {
            if (
                !financialConditions ||
                (!financialConditions?.conditions["apheresisVat"] &&
                    !financialConditions?.conditions["apheresisNoVat"])
            )
                return true;
            return false;
        }, [financialConditions]);

        /**
         * Recoil
         */
        const contractProducts = useRecoilValue(productsDetailsState);
        const { brand }: { brand: Brand & { name?: string } } = useRecoilValue(
            generalInformationState,
        );
        // Model state
        const { currency } = useRecoilValue(selectModelState);

        const [contractMilestones, updateContractMilestones] =
            useRecoilState<any>(contractMilestonesState);

        /**
         *  Update step hook
         */
        const updateStepValidation = useUpdateStep(
            location,
            createPerformanceBasedContractStepsState,
        );

        /**
         * Formik state
         */
        const formik: FormikProps<ContractMilestonesType> =
            useFormik<ContractMilestonesType>({
                initialValues: {
                    ...contractMilestones,
                },
                validationSchema: canAddMilestones
                    ? contractMilestonesSchema
                    : undefined,
                enableReinitialize: true,
                validateOnMount: true,
                onSubmit: () => undefined,
            });

        /**
         * Form validation
         */

        const { isValidForm, hasErrors } = useFormValidation(
            formik.errors,
            formik.touched,
            currentStep,
        );

        /**
         * Form validation check
         */
        const canSaveState =
            !canAddMilestones ||
            (isValidForm && isArrayWithContent(formik.values?.milestones));

        useEffect(() => {
            if (canAddMilestones) return;
            updateContractMilestones({ ...formik.values, milestones: [] });
        }, [canAddMilestones]);

        /**
         * Check if the current step is valid => set isPrepared to true,
         * otherwise set it false
         */
        useEffect(() => {
            updateStepValidation(canSaveState);
        }, [canSaveState, location]);

        /**
         * Save state and go to the next page (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            updateState() {
                updateContractMilestones(formik.values);
            },
        }));

        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={1}
            >
                <div>
                    <Box mb={7}>
                        <Typography variant="h2">
                            {"Pricing & milestones"}
                        </Typography>
                    </Box>

                    {canAddMilestones ? (
                        <ProductMilestonesForm
                            id={`${id}-milestones-form`}
                            isCreateContractFlow
                            onChange={formik.setFieldValue}
                            milestones={formik.values.milestones}
                            errors={formik.errors}
                            touched={formik.touched}
                            setTouched={formik.setTouched}
                            onBlur={formik.handleBlur}
                            productName={
                                contractProducts?.products[0]?.product
                                    ?.productName
                            }
                            brandName={brand?.brandName || brand?.name || ""}
                            sapSkuNo={
                                contractProducts?.products[0]?.product?.sapSkuNo
                            }
                            currency={currency}
                            price={
                                contractProducts?.products[0]?.priceCondition
                                    ?.price
                            }
                        />
                    ) : (
                        <Box mb={7}>
                            <Typography variant="h3">
                                Milestones are not applicable for a Apheresis
                                contract.
                            </Typography>
                        </Box>
                    )}
                </div>

                <FormFooter
                    error={hasErrors}
                    textAlign="right"
                    id={`${id}-milestones-footer`}
                    showText={canAddMilestones}
                />
            </Box>
        );
    },
);

export default ContractMilestones;
