import { useState } from "react";
import { useApiMapper } from "@hooks";

export const useTaskMapper = () => {
    /**
     * Api Mapper
     */
    const {
        mapOutcomeApprovalTask,
        mapReviewTask,
        mapClaimApprovalTask,
        mapClaimOverrideApprovalTask,
    } = useApiMapper();

    /**
     * States
     */
    const [values, setValues] = useState<{
        type: string;
        data: any;
    }>({
        type: "",
        data: {},
    });

    /**
     * Map data before submit
     * Check if the task is upload DMN, so we check if the dmn is already existing
     * Open dialogs
     */

    const setMappedValues = ({
        taskType,
        taskId,
        contractTask,
        orderTask,
        validateDmn,
        values,
    }) => {
        if (!taskType) return;

        const selectedTask = Object.keys(taskType).find(key => !!taskType[key]);
        const contractRef = contractTask?.contractRef;
        const orderId = orderTask?.id;

        if (!selectedTask) return;

        if (selectedTask === "isUploadDmn") {
            validateDmn(taskId, { dmnId: values.dmnId });
            setValues({
                type: selectedTask,
                data: { dmnId: values.dmnId, contractRef },
            });
        }

        if (selectedTask === "isOutcomeApprovalTask") {
            const {
                approved,
                rejectionReason,
                decisionInfo,
                overrideReason,
                newRebateAmount,
            } = values;

            const mappedData = mapOutcomeApprovalTask(
                contractRef,
                orderId,
                approved,
                rejectionReason,
                decisionInfo,
                overrideReason,
                newRebateAmount,
            );
            setValues({ type: selectedTask, data: mappedData });
        }

        if (selectedTask === "isInfusionDateChangeApprovalTask") {
            setValues({
                type: selectedTask,
                data: { approved: JSON.parse(values?.approved) },
            });
        }

        if (selectedTask === "isChangeContractDateApprovalTask") {
            setValues({
                type: selectedTask,
                data: { approved: JSON.parse(values?.approved) },
            });
        }

        if (selectedTask === "isReviewTask") {
            const mappedData = mapReviewTask(
                contractTask?.contractRef,
                values?.approved,
                values?.reasonRejection,
            );

            setValues({
                type: selectedTask,
                data: mappedData,
            });
        }

        if (selectedTask === "isCancelOutcomeTask") {
            const isApproved = values?.approved
                ? JSON.parse(values?.approved)
                : null;
            setValues({
                type: selectedTask,
                data: {
                    approved: isApproved,
                },
            });
        }

        if (selectedTask === "isClaimApprovalTask") {
            const {
                approved,
                rejectionReason,
                overrideReason,
                newClaimAmount,
            } = values;
            const mappedData = mapClaimApprovalTask(
                approved,
                rejectionReason,
                overrideReason,
                newClaimAmount,
            );

            setValues({
                type: selectedTask,
                data: mappedData,
            });
        }

        if (selectedTask === "isClaimOverrideApprovalTask") {
            const { approved, rejectionReason } = values;
            const mappedData = mapClaimOverrideApprovalTask(
                approved,
                rejectionReason,
            );

            setValues({
                type: selectedTask,
                data: mappedData,
            });
        }

        if (selectedTask === "isCancelClaimApprovalTask") {
            const isApproved = values?.approved
                ? JSON.parse(values?.approved)
                : null;
            setValues({
                type: selectedTask,
                data: {
                    approved: isApproved,
                },
            });
        }

        if (selectedTask === "isPriceDataApprovalTask") {
            const isApproved = values?.approved
                ? JSON.parse(values?.approved)
                : null;
            setValues({
                type: selectedTask,
                data: {
                    approved: isApproved,
                },
            });
        }

        if (
            selectedTask === "isEvidenceDataApprovalTask" ||
            selectedTask === "isLauerDataApprovalTask" ||
            selectedTask === "isIqviaDataApprovalTask" ||
            selectedTask === "isAccrualAdjustmentDataApprovalTask" ||
            selectedTask === "isPartnerDataApprovalTask" ||
            selectedTask === "isLocalAgreementDataApprovalTask" ||
            selectedTask === "isApheresisDataLoadApprovalTask" ||
            selectedTask === "isApproveBpfProcessingTask"
        ) {
            const isApproved = values?.approved
                ? JSON.parse(values?.approved)
                : null;
            setValues({
                type: selectedTask,
                data: {
                    approved: isApproved,
                },
            });
        }

        if (selectedTask === "isPriceCorrectionClaimApprovalTask") {
            const isApproved = values?.approved === "approved";

            setValues({
                type: selectedTask,
                data: {
                    approved: isApproved,
                    reset: values?.approved === "reset" ? true : undefined,
                    rejectionReason:
                        values?.approved === "rejected"
                            ? values?.rejectionReason
                            : undefined,
                },
            });
        }
        if (selectedTask === "isApproveBpfTask") {
            const isApproved = values?.approved === "approved";
            const isRecalled = values?.approved === "recalled";

            setValues({
                type: selectedTask,
                data: {
                    approved: isApproved,
                    recalled: isRecalled,
                },
            });
        }
    };

    return {
        setMappedValues,
        mappedValues: values,
        resetMappedValues: () =>
            setValues({
                type: "",
                data: {},
            }),
    };
};
