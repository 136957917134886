import { Box, Typography, Button } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useCallback, useMemo } from "react";

// Types
import { Location } from "@types";

// Hooks
import { useSelectedCountry } from "@hooks";

/**
 * Props type
 */

type Props = {
    location?: Location;
    onDataReady?: () => void;
    children: React.ReactNode;
};

interface FinancesLayoutRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Finances
 */
const FinancesLayout = React.forwardRef<FinancesLayoutRef, Props>(
    ({ location, onDataReady, children }, ref) => {
        const { isUkTeam } = useSelectedCountry();
        /**
         * Check if the page is active
         */
        const isActive = useCallback(
            page => {
                return location?.pathname?.includes(page);
            },
            [location],
        );

        const subPages = useMemo(() => {
            return [
                {
                    path: "/dashboard/finances/bpf/",
                    label: "Batch payment file (BPF)",
                },
                {
                    path: "/dashboard/finances/credit-note-outcome/",
                    label: "Credit note outcome",
                },
                {
                    path: "/dashboard/finances/credit-note-claim/",
                    label: "Credit note claim",
                    hidden: !isUkTeam,
                },
                {
                    path: "/dashboard/finances/invoice-release/",
                    label: "Invoice release",
                },
            ];
        }, [isUkTeam]);

        /**
         * Render
         */
        return (
            <Fragment>
                <Box mb={5} mt={3} display="flex" alignItems="center">
                    <Typography variant="caption2" mr={3}>
                        Transaction type:
                    </Typography>
                    {subPages.map(
                        (page, index) =>
                            !page?.hidden && (
                                <Button
                                    id={`dashboard-financial-transaction-type-btn-${index}`}
                                    key={index}
                                    onClick={() => navigate(page.path)}
                                    variant="outlined"
                                    size="large"
                                    sx={{
                                        fontSize: "0.875rem",
                                        backgroundColor: isActive(page.path)
                                            ? "#e6f5fc"
                                            : "none",
                                        borderColor: "transparent",
                                        padding: "0.3rem 1.3rem",
                                        marginRight: "1rem",
                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        {page.label}
                                    </Typography>
                                </Button>
                            ),
                    )}
                </Box>

                <Box mt={5}>
                    {React.cloneElement(children as React.ReactElement, {
                        onDataReady,
                        ref,
                    })}
                </Box>
            </Fragment>
        );
    },
);
export default FinancesLayout;
