//import { useApi } from "../api";
import { useEffect } from "react";
import { useAxios } from "./useAxios";
import type { Api } from "@types";

import { isSuccessfulCall, startDownload } from "@utils";

const BASE_URI = `/v0`;

export const useTasks = (isCompleted?: boolean) => {
    const url = `${BASE_URI}/tasks`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${url}?completed=${isCompleted ? "true" : "false"}`,
        },
        { errorHandler: "Failed to fetch tasks" },
    );

    const {
        response: task,
        loading: taskLoading,
        fetch: loadTask,
        error: taskError,
    }: Api = useAxios(
        {
            method: "GET",
        },
        { errorHandler: "Failed to fetch tasks" },
    );

    const {
        response: uploadDmnResponse,
        loading: uploadDmnLoading,
        fetch: uploadDmn,
        error: uploadDmnError,
    }: Api = useAxios(
        {
            method: "POST",
        },
        {
            errorHandler: "Failed to upload DMN table",
            successHandler: "DMN was successfully uploaded",
        },
    );

    const {
        response: approveTaskResponse,
        loading: approveTaskLoading,
        fetch: approveTask,
        error: approveTaskError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: reviewContractResponse,
        loading: reviewContractLoading,
        fetch: reviewContract,
        error: reviewContractError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: approveClaimResponse,
        loading: approveClaimLoading,
        fetch: approveClaim,
        error: approveClaimError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: approveClaimPriceCorrectionResponse,
        loading: approveClaimPriceCorrectionLoading,
        fetch: approveClaimPriceCorrection,
        error: approveClaimPriceCorrectionError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: cancelClaimResponse,
        loading: cancelClaimLoading,
        fetch: cancelClaim,
        error: cancelClaimError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: changeInfusionDateResponse,
        loading: changeInfusionDateLoading,
        fetch: changeInfusionDate,
        error: changeInfusionDateError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: approveBpfProcessingResponse,
        loading: approveBpfProcessingLoading,
        fetch: approveBpfProcessing,
        error: approveBpfProcessingError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: cancelOutcomeResponse,
        loading: cancelOutcomeLoading,
        fetch: cancelOutcome,
        error: cancelOutcomeError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: priceDataResponse,
        loading: priceDataLoading,
        fetch: approvePriceData,
        error: priceDataError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: loadDataResponse,
        loading: loadDataLoading,
        fetch: approveLoadData,
        error: loadDataError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: partnerDataResponse,
        loading: partnerDataLoading,
        fetch: approvePartnerData,
        error: partnerDataError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: changeContractDateResponse,
        loading: changeContractDateLoading,
        fetch: changeContractDate,
        error: changeContractDateError,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        response: approveBpfResponse,
        loading: approveBpfLoading,
        fetch: approveBpf,
        error: approveBpfError,
    }: Api = useAxios({
        method: "POST",
    });

    return {
        response: {
            list,
            task,
            uploadDmnResponse,
            approveTaskResponse,
            reviewContractResponse,
            changeInfusionDateResponse,
            changeContractDateResponse,
            cancelOutcomeResponse,
            approveClaimResponse,
            cancelClaimResponse,
            priceDataResponse,
            approveClaimPriceCorrectionResponse,
            loadDataResponse,
            partnerDataResponse,
            approveBpfProcessingResponse,
            approveBpfResponse,
        },
        loading: {
            listLoading,
            taskLoading,
            uploadDmnLoading,
            approveTaskLoading,
            reviewContractLoading,
            changeInfusionDateLoading,
            changeContractDateLoading,
            cancelOutcomeLoading,
            approveClaimLoading,
            cancelClaimLoading,
            priceDataLoading,
            approveClaimPriceCorrectionLoading,
            loadDataLoading,
            partnerDataLoading,
            approveBpfProcessingLoading,
            approveBpfLoading,
        },
        error: {
            listError,
            taskError,
            uploadDmnError,
            approveTaskError,
            reviewContractError,
            changeInfusionDateError,
            changeContractDateError,
            cancelOutcomeError,
            approveClaimError,
            cancelClaimError,
            priceDataError,
            approveClaimPriceCorrectionError,
            loadDataError,
            partnerDataError,
            approveBpfProcessingError,
            approveBpfError,
        },
        load: loadList,
        search: (searchParams?: string) =>
            searchParams
                ? loadList({
                      url: `${url}?completed=${
                          isCompleted ? "true" : "false"
                      }${searchParams}`,
                  })
                : loadList(),
        loadTask: (id: string) =>
            loadTask({
                url: `${url}/${id}`,
            }),
        uploadDmn: (taskId: string, data: any) =>
            uploadDmn(
                {
                    url: `${url}/${taskId}/createDMN`,
                    data,
                },
                {},
            ),
        approveTask: (taskId: string, data: any) =>
            approveTask(
                {
                    url: `${url}/${taskId}/decideOutcome`,
                    data,
                },

                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve outcome`
                            : `Failed to reject outcome`,
                    successHandler:
                        data?.approved === true
                            ? `Outcome was successfully approved`
                            : `Outcome was successfully rejected`,
                },
            ),
        changeInfusionDate: (taskId: string, data: any) =>
            changeInfusionDate(
                {
                    url: `${url}/${taskId}/changeInfusionDate`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve infusion date change`
                            : `Failed to reject infusion date change`,
                    successHandler:
                        data?.approved === true
                            ? `Infusion date change was successfully approved`
                            : `Infusion date change was successfully rejected`,
                },
            ),

        approveBpfProcessing: (taskId: string, data: any) =>
            approveBpfProcessing(
                {
                    url: `${url}/${taskId}/approveBpfProcessing`,
                    data,
                },
                {
                    errorHandler: `Failed to approve BPF processing`,
                    successHandler: `BPF processing was successfully approved`,
                },
            ),
        reviewContract: (taskId: string, data: any) =>
            reviewContract(
                {
                    url: `${url}/${taskId}/reviewContract`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve the contract`
                            : `Failed to reject the contract`,
                    successHandler:
                        data?.approved === true
                            ? `Contract was successfully approved`
                            : `Contract was successfully rejected`,
                },
            ),
        cancelOutcome: (taskId: string, data: any) =>
            cancelOutcome(
                {
                    url: `${url}/${taskId}/cancelOutcome`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve outcome cancellation`
                            : `Failed to reject outcome cancellation`,
                    successHandler:
                        data?.approved === true
                            ? `Outcome cancellation was successfully approved`
                            : `Outcome cancellation was successfully rejected`,
                },
            ),
        cancelClaim: (taskId: string, data: any) =>
            cancelClaim(
                {
                    url: `${url}/${taskId}/cancelClaim`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve claim cancellation`
                            : `Failed to reject claim cancellation`,
                    successHandler:
                        data?.approved === true
                            ? `Claim cancellation was successfully approved`
                            : `Claim cancellation was successfully rejected`,
                },
            ),

        approveClaim: (taskId: string, data: any) =>
            approveClaim(
                {
                    url: `${url}/${taskId}/decideClaim`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve claim task`
                            : `Failed to reject claim task`,
                    successHandler:
                        data?.approved === true
                            ? `Claim task was successfully approved`
                            : `Claim task was successfully rejected`,
                },
            ),
        approveClaimPriceCorrection: (taskId: string, data: any) =>
            approveClaimPriceCorrection(
                {
                    url: `${url}/${taskId}/decideClaim`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve claim task`
                            : `Failed to reject claim task`,
                    successHandler:
                        data?.approved === true
                            ? `Claim task was successfully approved`
                            : `Claim task was successfully rejected`,
                },
            ),
        approvePriceData: (taskId: string, data: any) =>
            approvePriceData(
                {
                    url: `${url}/${taskId}/decidePriceData`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve the upload of product prices for the Open House contracts`
                            : `Failed to reject the upload of product prices for the Open House contracts`,
                    successHandler:
                        data?.approved === true
                            ? `The upload of product prices for the Open House contracts was successfully approved`
                            : `The upload of product prices for the Open House contracts was successfully rejected`,
                },
            ),

        approveLoadData: (taskId: string, data: any) =>
            approveLoadData(
                {
                    url: `${url}/${taskId}/decideLoadData`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve the processing of the data in the uploaded file`
                            : `Failed to reject the processing of the data in the uploaded file`,
                    successHandler:
                        data?.approved === true
                            ? `The processing of the data in the uploaded file was successfully approved`
                            : `The processing of the data in the uploaded file was successfully rejected`,
                },
            ),
        changeContractDate: (taskId: string, data: any) =>
            changeContractDate(
                {
                    url: `${url}/${taskId}/changeContractDate`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve contract date change`
                            : `Failed to reject contract date change`,
                    successHandler:
                        data?.approved === true
                            ? `Contract date change was successfully approved`
                            : `Contract date change was successfully rejected`,
                },
            ),
        approvePartnerData: (taskId: string, data: any) =>
            approvePartnerData(
                {
                    url: `${url}/${taskId}/decidePartner`,
                    data,
                },
                {
                    errorHandler:
                        data?.approved === true
                            ? `Failed to approve Partner data`
                            : `Failed to reject Partner data`,
                    successHandler:
                        data?.approved === true
                            ? `Partner data was successfully approved`
                            : `Partner data was successfully rejected`,
                },
            ),
        approveBpf: (taskId: string, data: any) =>
            approveBpf(
                {
                    url: `${url}/${taskId}/decideBpf`,
                    data,
                },
                {
                    errorHandler:
                        data?.recalled === true
                            ? "Failed to recall BPF"
                            : data?.approved === true
                              ? `Failed to approve BPF`
                              : `Failed to reject BPF`,
                    successHandler:
                        data?.recalled === true
                            ? "BPF was successfully recalled"
                            : data?.approved === true
                              ? `BPF was successfully approved`
                              : `BPF was successfully rejected`,
                },
            ),
    };
};

/**
 * Fetch task definition keys
 */
export const useGetTaskDefinitionKeys = () => {
    const url = `${BASE_URI}/taskDefinitionKeys`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch the task definition keys` },
        true,
    );

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            loadList();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    return {
        list: list,
        loading: listLoading,
        error: listError,
        load: loadList,
    };
};

export const usePriceDataFile = (taskId: string) => {
    const url = `${BASE_URI}/pricedata/${taskId}`;

    const {
        loading,
        error,
        fetch: download,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to download file` },
    );

    return {
        loading,
        error,
        download: (name: string) =>
            download(
                {
                    url,
                },
                {
                    errorHandler: `Failed to download ${name}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
    };
};

export const useLoadDataFile = (taskId: string) => {
    const url = `${BASE_URI}/loadData/${taskId}/download`;

    const {
        loading,
        error,
        fetch: download,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to download file` },
    );

    return {
        loading,
        error,
        download: (name: string) =>
            download(
                {
                    url,
                },
                {
                    errorHandler: `Failed to download ${name}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
    };
};
