import React from "react";

export const blockInvalidNumber = (
    e: React.KeyboardEvent,
    noFloat?: boolean,
) => {
    if (noFloat) {
        ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault();
    } else ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
};

export const mapSeparators = (): {
    thousandsSeparator: string;
    decimalSeparator: string;
} => {
    const storedTeam = localStorage.getItem("__PICKED_TEAM");

    if (!storedTeam) return { thousandsSeparator: ".", decimalSeparator: "," };
    const team = storedTeam ? JSON.parse(storedTeam) : {};
    const countryIsoCode = team?.country?.countryIsoCode;

    return {
        thousandsSeparator: countryIsoCode === "GB" ? "," : ".",
        decimalSeparator: countryIsoCode === "GB" ? "." : ",",
    };
};

export const thousandsSeparator = (
    number: string | number | null | undefined,
) => {
    const { thousandsSeparator, decimalSeparator } = mapSeparators();
    if (
        number === undefined ||
        number === null ||
        (typeof number !== "number" && typeof number !== "string") ||
        !thousandsSeparator ||
        !decimalSeparator
    )
        return;

    const parsedNumber = typeof number == "number" ? number.toString() : number;
    const [wholePart, decimalPart = ""] = parsedNumber.split(".");
    const reversedWholePart = wholePart.split("").reverse().join("");

    let formattedWholePart = "";
    for (let i = 0; i < reversedWholePart.length; i++) {
        if (i > 0 && i % 3 === 0) {
            formattedWholePart = thousandsSeparator + formattedWholePart;
        }
        formattedWholePart = reversedWholePart[i] + formattedWholePart;
    }

    const formattedNumber =
        decimalPart.length > 0
            ? `${formattedWholePart}${decimalSeparator}${decimalPart}`
            : formattedWholePart;
    return formattedNumber;
};
