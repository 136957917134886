// icon
import AddIcon from "@mui/icons-material/Add";
// Lib
import { Box, Typography } from "@mui/material";
import classnames from "classnames";

//Own components
import { GrayBox } from "@components";

import * as React from "react";

//style
import * as style from "./style.module.scss";
import { isArrayWithContent } from "@utils";
/**
 * Props type
 */
interface Props {
    action?: {
        label: string;
        icon?: React.ReactNode;
        callback: () => void;
    };
    title?: string;
    id: string;
    items?: Array<string | undefined>;
}

/**
 * Highlight Card
 */
const HighlightCard: React.FC<Props> = ({ items, title, id, action }) => {
    return (
        <GrayBox
            id={`${id}-highlight-card`}
            header={
                <Box>
                    <Typography variant="h3">{title}</Typography>

                    <Box
                        display="flex"
                        className={classnames(style.details, {
                            [style.separator]: !!isArrayWithContent(items),
                        })}
                    >
                        {!!items &&
                            isArrayWithContent(items) &&
                            items.map(
                                (item, idx) =>
                                    !!item && (
                                        <Typography
                                            variant="subtitle2"
                                            key={idx}
                                            className={classnames({
                                                [style.item]:
                                                    !!item &&
                                                    items?.length > 0 &&
                                                    idx !== items.length - 1,
                                            })}
                                        >
                                            {item}
                                        </Typography>
                                    ),
                            )}
                    </Box>
                </Box>
            }
            primaryAction={
                action
                    ? {
                          text: action.label,
                          icon: action?.icon || <AddIcon />,
                          onClick: () => action.callback(),
                      }
                    : undefined
            }
        />
    );
};

export default HighlightCard;
