// Icons
import GridOn from "@mui/icons-material/GridOn";

// Libs
import { navigate } from "gatsby";

import React, { Fragment, useLayoutEffect } from "react";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    LoadingWrapper,
    BpfDetails,
} from "@components";
import { useGetBpfById } from "@hooks";

// Types
import type { Location } from "@types";

/**
 * Props type
 */
interface Props {
    location: Location;
    bpfId: string;
    id?: string;
}

/**
 * Finance Details
 */
const FinanceDetails = ({ location, bpfId, id = "bpf-details" }: Props) => {
    const {
        load: loadBpfDetails,
        loading: { loading, downloadingEvidence },
        list: bpfDetails,
        error: { bpfDetailsError },
        downloadEvidence,
    }: any = useGetBpfById();

    /**
     * Load BPF details
     */
    useLayoutEffect(() => {
        if (!bpfId) return;
        loadBpfDetails(bpfId);
    }, [bpfId]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={`bpf-details-breadcrumbs`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
                subPage={`BPF details`}
            />
            <FlowLayout>
                <LoadingWrapper
                    fullHeight
                    id={`view-contract-loading`}
                    loading={loading}
                    error={bpfDetailsError}
                    NoEmptyPage
                >
                    <Fragment>
                        <FlowAside>
                            <Stepper
                                id={`bpf-details-stepper`}
                                title={`BPF details`}
                                subTitle={bpfDetails?.data?.bpfName}
                            />
                        </FlowAside>

                        <FlowBody>
                            <Fragment>
                                <FlowContent>
                                    <BpfDetails
                                        {...bpfDetails?.data}
                                        bpfId={bpfId}
                                        downloadingEvidence={
                                            downloadingEvidence
                                        }
                                        downloadEvidence={downloadEvidence}
                                    />
                                </FlowContent>

                                <FlowFooter>
                                    <StepActions
                                        id={`bpf-details-step-actions`}
                                        primaryButton={{
                                            text: "Return to overview",
                                            action: () =>
                                                navigate(
                                                    `/dashboard/finances/bpf/`,
                                                ),
                                            isSubmitButton: true,
                                        }}
                                    />
                                </FlowFooter>
                            </Fragment>
                        </FlowBody>
                    </Fragment>
                </LoadingWrapper>
            </FlowLayout>
        </Fragment>
    );
};

export default FinanceDetails;
