// Libs
import { TableCell, TableRow, Typography, Checkbox, Box } from "@mui/material";
import React, { useMemo } from "react";

// Utils
import { displayDate, isArrayWithContent, thousandsSeparator } from "@utils";

interface Row {
    outcomeId: string;
    productName: string;
    therapeuticAreaName: string;
    contractRef: string;
    salessystemOrderId: string;
    amount: number;
    country: string;
    endDate: string;
    createdAt: string;
    sapSkuNo: string;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    callbacks: any;
    id: string;
    disabled?: boolean;
}

/**
 * Finances Outcomes
 */
const FinancesOutcomes = ({
    id,
    row,
    rowIndex,
    callbacks,
    disabled,
}: Props) => {
    /**
     * Check if the item is selected
     * don't return undefined to avoid warnings
     */
    const isItemSelected = useMemo(() => {
        if (
            !row?.outcomeId ||
            !callbacks?.selectedItems ||
            !isArrayWithContent(callbacks?.selectedItems)
        )
            return false;

        return callbacks?.selectedItems?.includes(row?.outcomeId);
    }, [callbacks?.selectedItems, row?.outcomeId]);

    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            selected={!!isItemSelected}
            onClick={event => {
                if (!disabled) {
                    callbacks.onSelect(
                        row?.outcomeId,
                        parseInt((event.target as HTMLInputElement).value),
                    );
                }
            }}
            sx={{ cursor: "pointer" }}
        >
            <TableCell>
                <Checkbox
                    onChange={event => {
                        callbacks.onSelect(
                            row?.outcomeId,
                            parseInt(event.target.value),
                        );
                    }}
                    disabled={disabled}
                    checked={!!isItemSelected}
                    size="small"
                    id={`${id}-row-${rowIndex}-outcome-contract`}
                />
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.contractRef}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.therapeuticAreaName}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.productName}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.sapSkuNo}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.salessystemOrderId}
                </Typography>
            </TableCell>

            <TableCell>
                <Box display="flex" flexDirection="column">
                    <Typography variant="caption2">
                        {displayDate(row.createdAt)}
                    </Typography>
                    <Typography variant="caption2">
                        {displayDate(row.endDate)}
                    </Typography>
                </Box>
            </TableCell>

            <Typography variant="caption2">
                {thousandsSeparator(row.amount)}
            </Typography>
        </TableRow>
    );
};

export default FinancesOutcomes;
