//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";

import React, { Fragment } from "react";

// Own components
import { Dialog } from "@components";

// Styles
import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onConfirm: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    values: {
        approved: string;
        recalled: string;
    };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    id: string;
}

/**
 * BPF approval task
 */
const BpfApprovalTask = ({
    id,
    openDialog,
    loading,
    onReset,
    className,
    values,
    onBlur,
    onChange,
    onConfirm,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`${id}-tasks-task-review`}
                title={"Complete BPF task"}
                open={openDialog}
                message={
                    (values?.approved === "approved" && (
                        <Typography variant="subtitle2" color="black">
                            You are about to approve the BPF for processing by
                            Finance. When the BPF processing date has arrived,
                            the BPF will be sent automatically to the Finance
                            department for processing. This action can not be
                            undone.
                        </Typography>
                    )) ||
                    (values?.approved === "rejected" && (
                        <Typography variant="subtitle2" color="black">
                            You are about to reject the BPF. This will set the
                            status of this BPF to &apos;Cancelled without
                            financial impact&apos;. This action can not be
                            undone.
                        </Typography>
                    )) ||
                    (values?.approved === "recalled" && (
                        <Typography variant="subtitle2" color="black">
                            Recalling the BPF will change the BPF status to
                            &apos;Draft&apos; again, please confirm this is what
                            you want to do?
                        </Typography>
                    ))
                }
                primaryButton={{
                    text: "Confirm",
                    action: onConfirm,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Typography variant="h2" className={style.header}>
                {`BPF approval`}
            </Typography>

            <Box mb={3} className={className}>
                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`${id}-task-details-task-review`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"approved"}
                                id={`${id}-task-details-review-contract-approve`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this decision
                                    </Typography>
                                }
                            />

                            <FormControlLabel
                                id={`${id}-task-details-review-contract-reject`}
                                value={"rejected"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this decision
                                    </Typography>
                                }
                            />

                            <FormControlLabel
                                value={"recalled"}
                                id={`${id}-task-details-review-contract-approve`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        Recall BPF (resets BPF to draft status)
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(BpfApprovalTask);
