import { useAxios } from "./useAxios";

// Type
import type { Api } from "@types";

// Utils
import { isSuccessfulCall, convertBase64 } from "@utils";

const BASE_URI = `/v0`;

export const useScanner = (fileType: "EXCEL" | "DEFAULT" = "DEFAULT") => {
    const {
        response: scannerResponse,
        loading: scanning,
        error: scannerError,
        fetch: scan,
    }: Api = useAxios(
        {
            method: "PUT",
            url: `${BASE_URI}/scan`,
        },
        { errorHandler: `Failed to scan file` },
    );

    const scanFiles = async (
        files: Array<any>,
        setFailedFiles: (files: Array<any>) => void,
        callback: (complete: Array<any>, flag?: boolean) => void,
        retry: (
            incomplete: Array<any>,
            retryCallback: (shouldRetry: boolean) => void,
        ) => void,
        flag?: boolean,
    ) => {
        const based64Files = await Promise.all(
            files.map(async (file, index) => {
                if (file?.file) {
                    return {
                        fileData: await convertBase64(file?.file),
                        fileType,
                        fileName: file?.file?.name,
                        originalKey: file?.originalKey,
                        fileDescription: file?.fileDescription,
                        index,
                    };
                }
                return file;
            }),
        );

        const mixedFiles = [...based64Files];

        const complete: Array<any> = [];
        const incomplete: Array<{ file: any; error: any }> = [];

        for (const file of mixedFiles) {
            try {
                const scanned = await scan({
                    data: {
                        fileData: file?.fileData,
                        fileType: file?.fileType,
                        fileName: file?.fileName,
                    },
                });

                if (isSuccessfulCall(scanned?.status)) {
                    complete.push({ ...file, ...scanned?.data });
                } else {
                    incomplete.push({
                        file,
                        error: new Error("Failed to upload file"),
                    });
                }
            } catch (error) {
                incomplete.push({
                    file,
                    error: new Error("Failed to scan file"),
                });
            }
        }

        if (incomplete.length > 0) {
            setFailedFiles(incomplete);
            retry(
                incomplete.map(item => item.file),
                (shouldRetry: boolean) => {
                    if (shouldRetry) {
                        scanFiles(
                            incomplete.map(item => item.file),
                            setFailedFiles,
                            callback,
                            retry,
                        );
                    } else {
                        callback(complete, flag);
                    }
                },
            );
        } else {
            setFailedFiles([]);
            callback(complete, flag);
        }
    };

    return {
        list: scannerResponse,
        loading: scanning,
        error: scannerError,
        scan: scanFiles,
    };
};
