import * as yup from "yup";

/**
 * Task details schema
 */

export const taskDetailsApprovalSchema = yup.object({
    approved: yup.string().required(),
    overrideReason: yup.string().when("approved", ([approved]) => {
        return approved === "approvedWithDifferentAmount"
            ? yup.string().required()
            : yup.string();
    }),
    newRebateAmount: yup.string().when("approved", ([approved]) => {
        return approved === "approvedWithDifferentAmount"
            ? yup.string().required()
            : yup.string();
    }),
    decisionInfo: yup.string().when("approved", ([approved]) => {
        return approved === "approvedWithDifferentAmount" ||
            approved === "rejected"
            ? yup.string().required()
            : yup.string();
    }),
    rejectionReason: yup.string().when("approved", ([approved]) => {
        return approved === "rejected" ? yup.string().required() : yup.string();
    }),
});

export const taskDetailsReviewContractSchema = yup.object({
    approved: yup.string().required(),
    reasonRejection: yup.string().when("approved", ([approved]) => {
        return approved === "false"
            ? yup.string().required()
            : yup.string().nullable();
    }),
});

export const taskDetailsClaimApprovalSchema = yup.object({
    approved: yup.string().required(),
    overrideReason: yup.string().when("approved", ([approved]) => {
        return approved === "approvedWithDifferentAmount"
            ? yup.string().required()
            : yup.string();
    }),
    newClaimAmount: yup.number().when("approved", ([approved]) => {
        return approved === "approvedWithDifferentAmount"
            ? yup.number().min(1).required()
            : yup.number();
    }),
    rejectionReason: yup.string().when("approved", ([approved]) => {
        return approved === "rejected" ? yup.string().required() : yup.string();
    }),
});

export const taskDetailsChangeInfusionDateSchema = yup.object({
    approved: yup.string().required(),
});

export const taskDetailsApproveBpfProcessingTask = yup.object({
    approved: yup.string().required(),
});

export const taskDetailsChangeContractDateSchema = yup.object({
    approved: yup.string().required(),
});

export const taskDetailsUploadDmnSchema = yup.object({
    dmnId: yup.string().required(),
    xml: yup.string(),
});

export const taskDetailsCancelOutcomeSchema = yup.object({
    approved: yup.string().required(),
});

export const taskDetailsOverrideApprovalSchema = yup.object({
    approved: yup.string().required(),
    rejectionReason: yup.string().when("approved", ([approved]) => {
        return approved === "false" ? yup.string().required() : yup.string();
    }),
});

export const taskDetailsCancelClaimSchema = yup.object({
    approved: yup.string().required(),
});

export const taskDetailsPriceDataSchema = yup.object({
    approved: yup.string().required(),
});

export const taskDetailsLoadDataSchema = yup.object({
    approved: yup.string().required(),
});

export const taskDetailsPriceCorrectionClaimApprovalTask = yup.object({
    approved: yup.string().required(),
    rejectionReason: yup.string().when("approved", ([approved]) => {
        return approved === "rejected" ? yup.string().required() : yup.string();
    }),
    reset: yup.string().nullable(),
});

export const taskDetailsPartnerDataSchema = yup.object({
    approved: yup.string().required(),
});

export const taskDetailsBpfApprovalSchema = yup.object({
    approved: yup.string().required(),
    recalled: yup.string().when("approved", ([approved]) => {
        return approved === "false" ? yup.string().required() : yup.string();
    }),
});
