// icon
import AddIcon from "@mui/icons-material/Add";
//lib
import {
    Box,
    Button,
    Divider,
    RadioGroup,
    FormControlLabel,
    InputLabel,
    Radio,
    TextField,
    Typography,
    InputAdornment,
} from "@mui/material";

import React, { Fragment, useEffect, useState, useMemo } from "react";
import { NumericFormat } from "react-number-format";

// Types
import type { FinancialCondition } from "@types";

// Utils
import {
    isArrayWithContent,
    isObjectWithContent,
    thousandsSeparator,
    mapSeparators,
} from "@utils";

//Own components
import { Table, Dialog, Modal, HighlightCard } from "@components";

// Constants
import { ROWRENDERERCONST, HEADERS } from "@constants";

// Style
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    financialConditions: Record<string, any>;
    onChange: (conditions: any) => void;
    errors?: Record<string, any>;
    touched: Record<any, any>;
    setTouched?: any;
    onBlur: (event: React.SyntheticEvent) => void;
    financialConditionsList?: {
        load: () => void;
        data: Array<FinancialCondition> | undefined;
        loading: boolean;
    };
    id: string;
    noTitle?: boolean;
    canDelete?: boolean;
    productName: string;
    brandName: string;
    sapSkuNo: string;
    currency: string;
    price: string;
    resetRelatedStates?: () => void;
    isCreateContractFlow?: boolean;
}

const CONDITIONS = {
    REBATE_AT_INFUSION: "rebateAtInfusion",
    APHERESIS_NO_VAT: "apheresisNoVat",
    APHERESIS_VAT: "apheresisVat",
};

/**
 * Product Financial Conditions Form
 */
const ProductFinancialConditionsForm: React.FC<Props> = ({
    financialConditions,
    onChange,
    errors,
    touched,
    setTouched,
    onBlur,
    financialConditionsList,
    id,
    noTitle,
    canDelete = true,
    productName,
    brandName,
    sapSkuNo,
    currency,
    price,
    resetRelatedStates,
    isCreateContractFlow,
}) => {
    const [showConditions, toggleConditionsModal] = useState(false);
    const [conditionToDelete, setConditionToDelete] = useState<any>(undefined);

    /**
     * Get condition
     */
    const condition = useMemo(() => {
        if (!isArrayWithContent(Object.keys(financialConditions))) return;

        const [mappedCondition] = Object.entries(financialConditions);

        return {
            key: mappedCondition[0],
            value: mappedCondition[1],
            isApheresisBased: ["apheresisNoVat", "apheresisVat"].includes(
                mappedCondition[0],
            ),
        };
    }, [financialConditions]);

    /**
     * Select condition
     */
    const onSelectCondition = (
        financialConditionTypeId: string,
        description?: string,
        name?: string,
    ) => {
        const copyConditions: any = { ...financialConditions };
        const type = CONDITIONS[financialConditionTypeId];
        Object.assign(copyConditions, {
            [type]: {
                description,
                amount: undefined,
                selectedCondition: null,
                name,
            },
        });

        onChange(copyConditions);
    };

    /**
     * Edit conditions
     */
    const onEditCondition = (
        key: string,
        condition: string | number | undefined,
        type: string,
    ) => {
        if (!type) return;

        const copyConditions = { ...financialConditions };

        if (copyConditions[type]) {
            copyConditions[type] = {
                ...copyConditions[type],
                [key]: condition,
                amount: key === "selectedCondition" ? undefined : condition,
            };
        }

        onChange(copyConditions);
    };

    /**
     * Delete handler
     */
    const handleDelete = () => {
        const filteredConditions: any = { ...financialConditions };
        delete filteredConditions[conditionToDelete];

        const copyTouched = { ...touched };
        if (copyTouched[conditionToDelete]) {
            delete copyTouched[conditionToDelete];
        }

        if (resetRelatedStates) {
            resetRelatedStates();
        }
        !!setTouched && setTouched(copyTouched);
        onChange(filteredConditions);
        setConditionToDelete(undefined);
    };

    useEffect(() => {
        if (!showConditions) return;
        if (financialConditionsList?.load) {
            financialConditionsList.load();
        }
    }, [showConditions]);

    /**
     * Check if field has error
     */

    const transformAmountError = useMemo(() => {
        if (!financialConditions || !condition?.key) return;
        const mappedCondition = financialConditions[condition?.key];

        return (
            (mappedCondition?.selectedCondition === "rebatePercentage" &&
                Number(mappedCondition["amount"]) > 100) ||
            (!errors &&
                !!touched[`${condition?.key}.amount`] &&
                !mappedCondition["amount"]) ||
            (!!condition?.key &&
                !!errors?.conditions &&
                !!errors?.conditions[condition?.key]?.amount &&
                !!touched[condition?.key]?.amount)
        );
    }, [errors, touched, financialConditions]);

    const separators = useMemo(() => mapSeparators(), []);

    /**
     * Render
     */
    return (
        <Box mt={2}>
            {!noTitle && (
                <Box mb={3} mt={7}>
                    <Typography variant="h3">
                        {"Financial Conditions"}
                    </Typography>
                </Box>
            )}

            <form noValidate className={style.card} onBlur={onBlur}>
                <Dialog
                    open={!!conditionToDelete}
                    id={`${id}-delete-condition`}
                    message={
                        <Typography
                            variant="subtitle2"
                            color="black"
                            component="span"
                        >
                            Are you sure you would like to delete this financial
                            condition for{" "}
                            <Typography
                                variant="body2"
                                component="span"
                            >{`${productName} (${brandName})? `}</Typography>
                            This will permanently remove the associated data for
                            the upcoming steps, and this action cannot be
                            undone.
                        </Typography>
                    }
                    primaryButton={{
                        text: "Delete financial condition",
                        action: () => handleDelete(),
                    }}
                    secondaryButton={{
                        text: "Cancel",
                        action: () => setConditionToDelete(undefined),
                    }}
                />

                <HighlightCard
                    id={`${id}-add-financial-conditions`}
                    title={`${productName} (${brandName})`}
                    items={[
                        sapSkuNo,
                        isCreateContractFlow && !!currency && !!price
                            ? ` ${thousandsSeparator(price)} ${currency}`
                            : price
                              ? price
                              : undefined,
                    ]}
                    action={
                        !isObjectWithContent(financialConditions)
                            ? {
                                  label: "Add financial condition",
                                  callback: () => toggleConditionsModal(true),
                              }
                            : undefined
                    }
                />

                <Modal
                    open={showConditions}
                    onClose={() => toggleConditionsModal(false)}
                    title="Financial conditions"
                    id={`${id}-financial-conditions-list`}
                >
                    <Table
                        headers={HEADERS.FINANCIAL_CONDITIONS}
                        rows={financialConditionsList?.data || []}
                        loading={
                            !!financialConditionsList &&
                            financialConditionsList?.loading
                        }
                        type={ROWRENDERERCONST.FINANCIAL_CONDITIONS}
                        id={`${id}-financial-conditions-list`}
                        emptyMsg={"No data"}
                        callbacks={{
                            selectCondition: ({
                                financialConditionTypeId,
                                description,
                                name,
                            }) => {
                                onSelectCondition(
                                    financialConditionTypeId,
                                    description,
                                    name,
                                );
                                toggleConditionsModal(false);
                            },
                        }}
                        isOverview={false}
                    />
                </Modal>

                {!!condition && (
                    <div className={style.list}>
                        {!!condition && (
                            <Fragment>
                                <div className={style.headerAction}>
                                    <Typography variant="caption1Dark">
                                        {condition?.value?.name}
                                    </Typography>
                                    {canDelete && (
                                        <Button
                                            id={`${id}-delete-condition`}
                                            variant="text"
                                            color="primary"
                                            onClick={() =>
                                                setConditionToDelete(
                                                    condition?.key,
                                                )
                                            }
                                        >
                                            Delete financial condition
                                        </Button>
                                    )}
                                </div>

                                <Typography
                                    variant="caption1"
                                    className={style.description}
                                >
                                    {condition?.value?.description}
                                </Typography>

                                <div className={style.body}>
                                    <RadioGroup
                                        id={`${id}-conditions-group`}
                                        value={
                                            condition?.value
                                                ?.selectedCondition || ""
                                        }
                                        onChange={event =>
                                            onEditCondition(
                                                "selectedCondition",
                                                event.target.value,
                                                condition?.key,
                                            )
                                        }
                                        name={`${condition?.key}.selectedCondition`}
                                    >
                                        <FormControlLabel
                                            value={"fixedRebateAmount"}
                                            control={<Radio size="small" />}
                                            id={`${id}-fixed-rebate-amount-option`}
                                            label={
                                                <Typography
                                                    variant="body2"
                                                    color="dark.light"
                                                >
                                                    {`Fixed rebate amount${condition?.isApheresisBased ? " (without VAT)" : ""}`}
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            id={`${id}-rebate-percentage-option`}
                                            value={"rebatePercentage"}
                                            control={<Radio size="small" />}
                                            label={
                                                <Typography
                                                    variant="body2"
                                                    color="dark.light"
                                                >
                                                    Percentage on price
                                                </Typography>
                                            }
                                        />
                                    </RadioGroup>
                                    <Box width={"50%"}>
                                        <InputLabel
                                            shrink
                                            className={style.label}
                                            id={`${id}-rebate-at-infusion-label`}
                                            error={!!transformAmountError}
                                        >
                                            {`${
                                                condition?.value
                                                    ?.selectedCondition ===
                                                "rebatePercentage"
                                                    ? "Percentage (*)"
                                                    : `Amount ${condition?.isApheresisBased ? "(without VAT)" : ""} (*)`
                                            }`}
                                        </InputLabel>

                                        <NumericFormat
                                            id={`${id}-rebate-at-infusion-amount`}
                                            fullWidth
                                            name={`${condition?.key}.amount`}
                                            autoComplete="off"
                                            value={
                                                condition?.value?.amount || ""
                                            }
                                            error={!!transformAmountError}
                                            thousandSeparator={
                                                separators.thousandsSeparator
                                            }
                                            disabled={
                                                !condition?.value
                                                    ?.selectedCondition
                                            }
                                            decimalSeparator={
                                                separators.decimalSeparator
                                            }
                                            customInput={TextField}
                                            allowNegative={false}
                                            size="small"
                                            InputProps={{
                                                endAdornment: condition?.value
                                                    ?.selectedCondition ? (
                                                    <InputAdornment position="end">
                                                        <Typography variant="caption1">
                                                            {condition?.value
                                                                ?.selectedCondition ===
                                                            "rebatePercentage"
                                                                ? "%"
                                                                : currency}
                                                        </Typography>
                                                    </InputAdornment>
                                                ) : undefined,
                                            }}
                                            isAllowed={values => {
                                                const { floatValue } = values;
                                                return !!floatValue &&
                                                    condition?.value
                                                        ?.selectedCondition ===
                                                        "rebatePercentage"
                                                    ? floatValue <= 100
                                                    : true;
                                            }}
                                            onValueChange={({ floatValue }) => {
                                                onEditCondition(
                                                    "amount",
                                                    typeof floatValue ===
                                                        "number"
                                                        ? floatValue.toString()
                                                        : "",
                                                    condition?.key,
                                                );
                                            }}
                                        />

                                        {condition?.value?.selectedCondition ===
                                            "rebatePercentage" && (
                                            <Typography
                                                variant="caption1"
                                                color="text.disabled"
                                            >
                                                Value must be between 0 and 100,
                                                ex: 10%{" "}
                                            </Typography>
                                        )}
                                    </Box>
                                </div>
                            </Fragment>
                        )}
                        {!isObjectWithContent(financialConditions) && (
                            <Fragment>
                                <Divider />
                                <Button
                                    id={`${id}-add-financial-condition-btn`}
                                    variant="text"
                                    startIcon={<AddIcon />}
                                    color="primary"
                                    className={style.footerAction}
                                    onClick={() => toggleConditionsModal(true)}
                                    disabled={isObjectWithContent(
                                        financialConditions,
                                    )}
                                >
                                    Financial condition
                                </Button>
                            </Fragment>
                        )}
                    </div>
                )}
            </form>
        </Box>
    );
};
export default React.memo(ProductFinancialConditionsForm);
