// Lib
import { Box, Typography } from "@mui/material";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

// Own components
import { Dialog, SearchBar, Table } from "@components";

//Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Custom hooks
import {
    useBrands,
    useOutcome,
    useTeamTherapeuticArea,
    useProducts,
    useGetContracts,
    useUpdateStep,
} from "@hooks";

// Recoil
import {
    BPFGeneralInformationState,
    BPFPaymentInformationState,
    selectClaimsState,
    selectContractsState,
    selectOutcomesState,
} from "@atoms";

// Types
import type { Contract, Location, SearchFilters } from "@types";

/**
 * Utils
 */
import {
    apiResponseCounter,
    constructQueryString,
    isArrayWithContent,
} from "@utils";

/**
 * Props
 */
interface Props {
    // setContract?: (value: any) => void;
    contractId?: string;
    id?: string;
    isVolumeBased?: boolean;
    isApheresisBased?: boolean;
    flowState?: string;
    location?: Location;
    resetBpfStatePartially?: () => void;
}

/**
 * SelectContract
 */
const SelectContract = React.forwardRef(
    (
        {
            contractId,
            id,
            isVolumeBased,
            isApheresisBased,
            location,
            flowState,
            resetBpfStatePartially,
        }: Props,
        ref,
    ) => {
        /**
         * Show the warning to reset outcomes or claims
         */
        const [showWarning, setShowWarning] = useState<Contract | undefined>();

        /**
         * Update step validation
         */
        const updateStepValidation = useUpdateStep(location, flowState);

        /**
         * Recoil
         */
        const [selectedContracts, updateContracts] =
            useRecoilState(selectContractsState);

        const resetBPFPaymentInformationState = useResetRecoilState(
            BPFPaymentInformationState,
        );
        const resetSelectedClaimsState = useResetRecoilState(selectClaimsState);
        const resetSelectedOutcomesState =
            useResetRecoilState(selectOutcomesState);
        /**
         * Contracts
         */
        const {
            list: outcomeContractList,
            loading: fetchingOutcomeContracts,
            search: searchOutcomeContracts,
        } = useOutcome(`contracts`, "contract|contracts");

        /**
         * Claim contracts
         */
        const {
            list: claimContractsList,
            loading: fetchingClaimContracts,
            search: searchClaimContracts,
        } = useGetContracts();

        /**
         * Brands
         */
        const { list: brands, loading: brandsLoading } = useBrands();

        /**
         * Therapeutic area
         */
        const { list: therapeuticAreas, loading: therapeuticAreasLoading } =
            useTeamTherapeuticArea();

        /**
         * Products
         */
        const { response: product, loading: productLoading } = useProducts();

        /**
         * Use contract partner to get the contracts list
         */
        const BPFGeneralInformation = useRecoilValue(
            BPFGeneralInformationState,
        );

        /**
         * On search
         */
        const onSearch = (filters: SearchFilters) => {
            const hasFilters = Object.values(filters).some(
                filter => filter.length,
            );

            const params =
                constructQueryString(filters) +
                `&account=${BPFGeneralInformation?.contractPartner?.accountId}`;

            if (isVolumeBased) {
                if (hasFilters)
                    searchClaimContracts(
                        `?status=INACTIVE&status=ACTIVE&status=CLOSED&type=VOLUME${params}`,
                    );
                else
                    searchClaimContracts(
                        `?status=INACTIVE&status=ACTIVE&status=CLOSED&type=VOLUME&account=${BPFGeneralInformation?.contractPartner?.accountId}`,
                    );
            } else {
                if (hasFilters || isApheresisBased)
                    searchOutcomeContracts(
                        `${isApheresisBased ? "?type=REBATE_AT_APHERESIS" : "?"}${params}`,
                    );
                else
                    searchOutcomeContracts(
                        `?account=${BPFGeneralInformation?.contractPartner?.accountId}`,
                    );
            }
        };

        /**
         * On select contract
         */
        const onSelect = (row: Contract) => {
            // If contract is already selected, remove it
            const isSelected = selectedContracts.some(
                contract => contract.id === row.id,
            );

            if (isSelected) {
                const updatedContracts = selectedContracts.filter(
                    contract => contract.id !== row.id,
                );

                updateContracts(updatedContracts);
                return;
            }

            // If contract is not selected, add it
            const updatedSelectedContracts = [...selectedContracts, row];
            updateContracts(updatedSelectedContracts);
        };

        /**
         * pass the state back to the layout / higher order component
         */
        useImperativeHandle(ref, () => ({
            onSubmit() {
                return selectedContracts;
            },
        }));

        useEffect(() => {
            if (!isArrayWithContent(selectedContracts)) {
                resetBPFPaymentInformationState();
                resetSelectedClaimsState();
                resetSelectedOutcomesState();
                !!resetBpfStatePartially && resetBpfStatePartially();
            }
        }, [selectedContracts]);

        /**
         * Check if the current step is valid
         * then set isPrepared to true, otherwise set it false
         */

        useEffect(() => {
            if (!isArrayWithContent(selectedContracts)) return;
            const canSaveState = isArrayWithContent(selectedContracts);

            updateStepValidation(canSaveState);
        }, [selectedContracts]);

        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
            >
                <Dialog
                    open={!!showWarning}
                    id={`${id}-dialog-confirm-reset`}
                    message={
                        <Typography
                            variant="subtitle2"
                            color="black"
                            component="span"
                        >
                            {`You have already assigned ${isVolumeBased ? "claims" : "outcomes"}. adding more contracts will remove the current assigned ${isVolumeBased ? "claims" : "outcomes"}. Are you sure want to reset the flow?`}
                        </Typography>
                    }
                    primaryButton={{
                        text: "Yes",
                        action: () => {
                            onSelect(showWarning as Contract);
                            setShowWarning(undefined);
                        },
                        loading: false,
                    }}
                    secondaryButton={{
                        text: "No",
                        action: () => setShowWarning(undefined),
                    }}
                />
                <div>
                    <Box mb={7}>
                        <Typography variant="h2">Contract selection</Typography>
                    </Box>

                    <SearchBar
                        id={`${id}-contract-selection-search-bar`}
                        handleSearch={(filters: SearchFilters) =>
                            onSearch(filters)
                        }
                        simpleLayout
                        /*  partners={{
                            data: partners?.data,
                            loading: partnersLoading,
                            onSearch: (query: string) => getPartners(query),
                        }} */
                        products={{
                            data: product?.data?.records,
                            loading: productLoading,
                        }}
                        therapeuticAreas={{
                            data: therapeuticAreas?.data?.records,
                            loading: therapeuticAreasLoading,
                        }}
                        brands={{
                            data: brands?.data,
                            loading: brandsLoading,
                        }}
                        placeholder="Search by reference"
                        // searchCallback={() => !!setContract && setContract("")}
                    />
                    <Box display="flex" alignItems="baseline" mt={3}>
                        <Typography variant="h3">Search results</Typography>

                        <Typography ml={1} variant="caption1">
                            {apiResponseCounter(
                                outcomeContractList || claimContractsList,
                                fetchingOutcomeContracts ||
                                    fetchingClaimContracts,
                                "contract|contracts",
                            )}
                        </Typography>
                    </Box>

                    {(isArrayWithContent(outcomeContractList?.data?.records) ||
                        fetchingOutcomeContracts) && (
                        <Table
                            id={`${id}-outcome-contract-selection-list`}
                            headers={HEADERS.BPF_OUTCOME_CONTRACT_SELECTION}
                            rows={outcomeContractList?.data?.records}
                            loading={fetchingOutcomeContracts}
                            type={
                                ROWRENDERERCONST.BPF_OUTCOME_CONTRACT_SELECTION
                            }
                            maxHeight="30rem"
                            callbacks={{
                                onSelect,
                                canSelectMany: true,
                                selectedItems: selectedContracts,
                            }}
                            selectedRow={contractId}
                            emptyMsg="No contracts found!"
                            isOverview={false}
                        />
                    )}

                    {(isArrayWithContent(claimContractsList?.data?.records) ||
                        fetchingClaimContracts) && (
                        <Table
                            id={`${id}-claim-contract-selection-list`}
                            headers={HEADERS.CLAIM_CONTRACT}
                            rows={claimContractsList?.data?.records}
                            loading={fetchingClaimContracts}
                            type={ROWRENDERERCONST.CLAIM_CONTRACT}
                            maxHeight="30rem"
                            callbacks={{
                                onSelect,
                                canSelectMany: true,
                                selectedItems: selectedContracts,
                            }}
                            selectedRow={contractId}
                            emptyMsg="No contracts found!"
                            isOverview={false}
                        />
                    )}
                </div>
            </Box>
        );
    },
);

export default SelectContract;
