//import libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import classnames from "classnames";
// import own components
import { ActionMenu } from "@components";

// Import utils
import { displayDate, constantMapper } from "@utils";

// Styles
import * as style from "../../style.module.scss";

interface Row {
    id: string;
    status: string;
    bpfName: string;
    dateProcessed: string;
    bpfId: string;
    outcomeFileName: string;
    contractRef: string;
    primaryContractPartner: string;
    financeType: string;
    customerRef: string;
    contractType: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    permissions: any;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
}

const BPF = ({
    id,
    row,
    rowIndex,
    permissions,
    isColVisible,
    callbacks,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            className={classnames(style.row, {
                [style.isClickable]:
                    permissions.hasPermissionToReadFinancialActions ||
                    permissions?.hasPermissionToEditFinancialActions,
            })}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            onClick={() => {
                callbacks?.onBpfDetailsClick(row);
            }}
        >
            {isColVisible("bpfName") && (
                <TableCell>
                    <Typography variant="caption2">{row.bpfName}</Typography>
                </TableCell>
            )}

            {isColVisible("dateProcessed") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.dateProcessed)}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("contractRef") && (
                <TableCell>
                    <Typography variant="caption2">
                        {" "}
                        {row.contractRef}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("contractType") && (
                <TableCell>
                    <Typography variant="caption2">
                        {" "}
                        {constantMapper(row.contractType)}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("customerRef") && (
                <TableCell>
                    <Typography variant="caption2">{`${
                        row.customerRef || ""
                    }`}</Typography>
                </TableCell>
            )}
            {isColVisible("primaryContractPartner") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.primaryContractPartner}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("financeType") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row.financeType)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {" "}
                        {constantMapper(row.status)}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right" className={style.action}>
                {permissions?.hasPermissionToEditFinancialActions && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            autoHide
                            id={`${id}-${rowIndex}-bpf-row-btn-list"`}
                            stopPropagation={true}
                            options={[
                                {
                                    label: "Edit BPF",
                                    action: () =>
                                        callbacks?.onBpfEditClick(row),
                                    disabled: row.status !== "DRAFT",
                                },
                                {
                                    label: "Send for approval",
                                    action: () =>
                                        callbacks?.onSendForApprovalClick(row),
                                    disabled: row.status !== "DRAFT",
                                },
                                {
                                    label: "Download BPF report",

                                    action: () => callbacks?.onBpfDownload(row),
                                    disabled: false,
                                },
                                {
                                    label: "Recall BPF",
                                    action: () =>
                                        callbacks?.onRecalledClick(row),
                                    disabled: row.status !== "NEW",
                                },
                                {
                                    label: "Mark as processed",
                                    action: () =>
                                        callbacks?.onProcessedClick(row),
                                    disabled:
                                        !permissions?.hasPermissionToEditFinancialActions ||
                                        row.status !== "BPF_GENERATED",
                                },
                                {
                                    label: "Remove transactions",
                                    action: () =>
                                        callbacks?.onRemoveTransactionClick(
                                            row,
                                        ),
                                    disabled:
                                        !permissions?.hasPermissionToEditFinancialActions ||
                                        row.status !== "DRAFT",
                                },
                            ]}
                            rowIndex={rowIndex}
                        />
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

export default BPF;
